import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { JobformComponent } from '../jobform/jobform.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseService } from 'src/app/service/course.service';
import { JobService } from 'src/app/service/job.service';
import { AuthService } from 'src/app/service/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { Title, Meta } from '@angular/platform-browser';
import { Seo } from 'src/app/course/courseseo';
import { Jobs } from '../jobs';


@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.css']
})
export class JobdetailsComponent implements OnInit {
  jobDetails: Jobs;
  userInfo: any[];
  jobApplied: any[];
  authCheck: boolean;
  resume: string;
  jobSeo: Seo;
  constructor(private dialog: MatDialog, private jobServer: JobService,
              private route: ActivatedRoute, private authServer: AuthService,
              private title: Title, private meta: Meta, private courseService: CourseService
              ) { }
    jobForm(): void {
      if (this.authServer.isLogin() === true) {
        const dialogRef = this.dialog.open(JobformComponent, {
          width: '500px',
          data: {
            id: this.jobDetails._id,
            resume: this.resume
          }

        });
        dialogRef.afterClosed().subscribe(result => {
        });
      } else {
        this.dialog.open(LoginComponent, {
          width: '630px',
        });
      }

  }
  ngOnInit() {
    this.authCheck = this.authServer.isLogin();
   // tslint:disable-next-line: align
   this.route.params.subscribe(
     (param: Params) => {
        this.jobServer.getJobsDetails(param[`jobid`]).subscribe(Response => {
          this.jobDetails = Response[`response`];
          this.jobSeo = this.jobDetails.SEO[`0`];
          this.resume = Response[`user_info`].Resume_URL;
          this.userInfo = Response[`user_info`].JobsApplied;
          if (this.authServer.isLogin() === true) {
            this.jobApplied = this.userInfo.filter( res => res.Job_Id === this.jobDetails._id);
          }
          this.title.setTitle(this.jobDetails.Job_Title);
          if (typeof(this.jobSeo) !== 'undefined') {
      this.meta.updateTag({name: 'description', content: this.jobSeo.Meta_Description});
      this.meta.updateTag({name: 'keywords', content: this.jobSeo.Meta_Keywords});
      this.meta.updateTag({name: 'abstract', content: this.jobSeo.Meta_Abstract });
      this.meta.updateTag({name: 'Author', content: this.jobSeo.Meta_Author });
      this.meta.updateTag({name: 'copyright', content: this.jobSeo.Meta_Copyright });
      this.meta.updateTag({name: 'Distribution', content: this.jobSeo.Meta_Distribution });

      this.meta.updateTag({itemprop: 'name', content: this.jobSeo.Meta_Item_Prop_Name  });
      this.meta.updateTag({itemprop: 'image', content: this.jobSeo.Meta_Item_Prop_Image  });
      this.meta.updateTag({itemprop: 'description', content: this.jobSeo.Meta_Item_Prop_Description });
      this.meta.updateTag({property: 'article:author', content: this.jobSeo.Meta_Property_Article_Author });
      this.meta.updateTag({property: 'article:publisher', content: this.jobSeo.Meta_Property_Article_Publisher });


      this.meta.updateTag({property: 'og:type', content: this.jobSeo.Meta_Property_OG_Type });
      this.meta.updateTag({property: 'og:url', content: this.jobSeo.Meta_Property_OG_URL });
      this.meta.updateTag({property: 'og:site_name', content: this.jobSeo.Meta_Property_OG_SiteName });
      this.meta.updateTag({property: 'og:description', content: this.jobSeo.Meta_Property_OG_Description });
      this.meta.updateTag({property: 'og:title', content: this.jobSeo.Meta_Property_OG_Title });
      this.meta.updateTag({property: 'og:author', content: this.jobSeo.Meta_Property_OG_Author });
      this.meta.updateTag({property: 'og:image', content: this.jobSeo.Meta_Property_OG_Image });
    }
        });
     }
   );
  }

}
