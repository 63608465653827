import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
  
    // Replace actual newlines with <br> tags
    let formattedText = value.replace(/\n/g, '<br>');
  
    // Replace escaped \n (literal \n) with <br> (if needed)
    formattedText = formattedText.replace(/\\n/g, '<br>');
  
    // Replace **text** with <b>text</b> for bold text
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  
    // Wrap code blocks inside triple backticks with <pre> tags
    formattedText = formattedText.replace(/```([\s\S]*?)```/g, '<pre>$1</pre>');
  
    // Wrap inline code inside single backticks with <code> tags
    formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
  
    return formattedText;
  }
}