import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent {
  @ViewChild('chatChannel', { static: false }) chatChannel!: ElementRef; // Correctly include the static option
  // Variables for Introduction
  introductionAudioUrl: string = '';
  isRecordingIntroductionAllowed: boolean = false;
  isRecordingIntroduction: boolean = false;
  loaderMessage: string = 'Analyzing text...';
  aiTalk: boolean = false;

  lectureStarted: boolean = false;
  isCourseSelected: boolean = false;
  selectedCourse: string = '';
  isQueryRecording: boolean = false;
  introResponse: Boolean = false;
  erroResponse: boolean = false;
  queryAudio: string = '';

  errorMessageText: string = '';
  mediaRecorder: MediaRecorder | null = null;
  introRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  isQueryFinished: boolean = false;
  lecturePausedTime: number = 0;
  lectureAudioElement: HTMLAudioElement | null = null;
  queryAudioElement: HTMLAudioElement | null = null;
  replyAudioElement: HTMLAudioElement | null = null;
  isProcessing: boolean = false;
  chatMessages: ChatMessage[] = [];
  loaderTextIndex: number = 0;

  queryLoaderMessages: string[] = [
    'Processing your audio...',
    'Understanding your query...',
    'Generating a response...',
    'Analyzing audio data...'
  ];

  sections: string[] = [
    "Python Environment Setup & Essentials",
    "Python Basics: Language Constructs",
    "Operators in Python",
    "Working with Lists",
    "Understanding Tuples",
    "Sets and Frozen Sets",
    "Dictionaries in Python",
    "Program Control Flow in Python",
    "NumPy: Mathematical Computing",
    "SciPy: Scientific Computing",
    "Matplotlib: Data Visualization",
    "Pandas: Data Analysis and Machine Learning"
  ];

  userMessage: string = '';

  // New variables for lecture content
  currentLectureIndex: number = 0;
  lectureParts: { audio: string; text: string; moduleTitle: string }[] = [];
  currentLectureAudio: string | null = null;

  private loaderInterval: any;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  onCourseSelect(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.isCourseSelected = !!selectedValue;
    this.selectedCourse = selectedValue;
  }


  async ngOnInit() {
    try {
      const response = await fetch(
        `${environment.AWS_MICRO_SERVICE_API}introduce_tutor`,
        { method: 'POST' }
      );
      if (response.ok) {
        const data = await response.json();

        // Extract the introduction audio and text
        const { audio_url, introduction_text } = data;
        this.introductionAudioUrl =
          environment.AWS_MICRO_SERVICE_API + audio_url || '';
      } else {
        console.error('Failed to fetch introduction tutor data:', response.statusText);
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
    }
  }

  async playIntroduction(): Promise<void> {
    this.pauseLecture();
    this.playQueryResponse(this.introductionAudioUrl, true, false, false, 0);
    this.cdr.detectChanges();
    // Wait for introduction audio to finish
    if (this.queryAudioElement) {
      this.queryAudioElement.onended = () => {
        this.isRecordingIntroductionAllowed = true;
        this.aiTalk = false;
        this.cdr.detectChanges();
      };
    }
  }

  async startRecording(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.pauseLecture();
      this.isQueryRecording = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.chatMessages.push({
          text: "AUDIO FILE UPLOADED",
          type: "audio",
          isSent: true,
          replied: false,
          visited: false
        });
        this.scrollToBottom();
        this.cdr.detectChanges();

        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, false, this.currentLectureIndex);
        this.isQueryRecording = false;
        this.isRecordingIntroduction = false;
        this.isRecordingIntroductionAllowed = false;
        this.cdr.detectChanges();
      };

      this.mediaRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isQueryRecording = false;
    }
  }

  async startSelfIntro(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.isRecordingIntroduction = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.introRecorder = new MediaRecorder(stream);

      this.introRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.introRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, true, 0);
        this.isRecordingIntroduction = false;

        this.cdr.detectChanges();
      };

      this.introRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isRecordingIntroduction = false;
    }
  }

  stopIntroRecording(): void {
    if (this.introRecorder && this.introRecorder.state === 'recording') {
      this.isRecordingIntroductionAllowed = false;
      this.isRecordingIntroduction = false;
      this.introRecorder.stop();
    }
  }

  stopRecording(): void {
    this.isQueryRecording = false;
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop();
    }
  }

  async sendAudioToApi(audioBlob: Blob, chatQuery: string, isForIntro: boolean, lectureNumber: number): Promise<void> {
    try {
      const formData = new FormData();
      let bodyContent;
      let headers = {};
      if (audioBlob != null) {
        this.isProcessing = true;
        this.updateLoaderMessage(this.queryLoaderMessages);
        formData.append('audio', audioBlob, 'query_' + Date.now() + '.webm');
        bodyContent = formData;
      } else {
        const jsonPayload = {
          query: chatQuery,
        };
        bodyContent = JSON.stringify(jsonPayload);
        headers['Content-Type'] = 'application/json';
      }

      const request = isForIntro
        ? `${environment.AWS_MICRO_SERVICE_API}get_user_name`
        : `${environment.AWS_MICRO_SERVICE_API}handle_query_new`;

      const response = await fetch(request, {
        method: 'POST',
        headers,
        body: bodyContent,
      });


      if (response.ok) {
        const data = await response.json();
        this.queryAudio = environment.AWS_MICRO_SERVICE_API + (data.audio_url || '');
  
        this.chatMessages.push({
          replied: true,
          isSent: false,
          text: data.response_text || data.welcome_text || "Thanks for your input!",
          type: "text",
          visited: true
        });
        this.scrollToBottom();
        this.introResponse = isForIntro;
        this.isProcessing = false;
        this.cdr.detectChanges();
        await this.playQueryResponse(this.queryAudio, isForIntro, false, audioBlob == null, lectureNumber);
        this.queryAudio = '';
        this.cdr.detectChanges();
      } else {
        this.isProcessing = false;
        console.error('API error:', response.statusText);
      }
    } catch (error) {
      this.errorMessageText = `Failed to generate response. Please try again.`;
      this.erroResponse = true;
      this.isProcessing = false;
      this.cdr.detectChanges();
      console.error('Failed to send audio to API:', error);
    }
  }
  startLectureClicked = false;
  async startLecture(): Promise<void> {
    this.startLectureClicked = true;
    this.playIntroduction();
    this.cdr.detectChanges();
    this.generateLectureParts();

  }

  async generateLectureParts() {
    for (const section of this.sections) {
      try {
        const response = await fetch(`${environment.AWS_MICRO_SERVICE_API}generate_lecture`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ section })
        });

        if (response.ok) {
          const data = await response.json();

          // Extract the lecture audio and text pairs
          const { audio_url, lecture_text } = data;
          this.lectureParts.push({
            moduleTitle: section,
            text: lecture_text,
            audio: environment.AWS_MICRO_SERVICE_API + audio_url
          });

          this.currentLectureIndex = 0; // Start with the first module
          this.cdr.detectChanges();
        } else {
          console.error(`Error fetching lecture for section: ${section}`, response.statusText);
        }
      } catch (error) {
        console.error(`Error processing section: ${section}`, error);
      }
    }
  }


  isAudioVisible: boolean = false;
  isTextVisible: boolean = false;
  displayLectureModule(moduleNumber: number): void {
    this.currentLectureIndex = moduleNumber;
    this.aiTalk = true;
    this.lectureStarted = true;
    const currentPart = this.lectureParts[this.currentLectureIndex];

    this.currentLectureAudio = currentPart.audio;
    this.cdr.detectChanges();
    if (this.currentLectureAudio) {
      // Show the lecture text and hide audio
      this.isAudioVisible = false;
      this.isTextVisible = true;
      this.lectureAudioElement = null;
      this.lectureAudioElement = new Audio(this.currentLectureAudio);
      this.aiTalk = true;
      this.cdr.detectChanges();
      this.lectureAudioElement.play();
      this.lectureAudioElement.onended = () => {
        this.aiTalk = false;
        this.isTextVisible = false;  // Hide text after audio finishes
        this.isAudioVisible = true;  // Optionally, show audio player again
        this.currentLectureIndex++;
        if (this.currentLectureIndex < this.lectureParts.length) {
          setTimeout(() => this.changeLecture(this.currentLectureIndex), 1500);
        } else {
          this.aiTalk = false;
        }
      };
    }
  }

  continueCourse(): void {
    this.pauseQueryResponse();
    const queryAudio = "assets/audio/no_reply.mp3";
    this.replyResponse(queryAudio, false, null);
  }

  haveDoubts(): void {
    const queryAudio = "assets/audio/yes_reply.mp3";
    this.replyResponse(queryAudio, true, null);
    this.isQueryFinished = false;
  }

  changeLecture(lectureNumber: number): void {
    if (this.lectureAudioElement) {
      this.pauseLecture();
      this.cdr.detectChanges();

      const sessionPause = "assets/audio/play_after_each_session_end.mp3";
      this.replyResponse(sessionPause, false, lectureNumber);
    }
  }

  // Method to end the course, stop audio, reset flags
  endCourse(): void {
    if (this.lectureAudioElement) {
      this.pauseLecture();
      this.lectureAudioElement.currentTime = 0; // Reset the audio position to the start
    }
    this.aiTalk = false;
    this.currentLectureIndex = 0; // Reset lecture index to the beginning
    this.currentLectureAudio = null;
    this.isQueryRecording = false; // Disable query recording if any
    this.lectureStarted = false;
  }

  async replyResponse(audioFile: string, haveDoubts: boolean, lectureNumber: number) {
    this.replyAudioElement = null;
    this.replyAudioElement = new Audio(audioFile);
    this.pauseQueryResponse();

    this.aiTalk = true;
    this.cdr.detectChanges();
    this.replyAudioElement.play();
    this.replyAudioElement.onended = async () => {
      this.aiTalk = false;
      if (!haveDoubts) {
        if (lectureNumber == null) {
          if (this.lectureAudioElement) {
            this.aiTalk = true;
            this.lectureAudioElement.currentTime = this.lecturePausedTime;
            this.cdr.detectChanges();
            this.lectureAudioElement.play();

            this.lectureAudioElement.onended = () => {
              this.currentLectureIndex++;
              if (this.currentLectureIndex < this.lectureParts.length) {
                setTimeout(() => this.displayLectureModule(this.currentLectureIndex), 1500);
              } else {
                this.endCourse(); // End the course once all parts are completed
              }
            };
          }
        } else {

          const filterChatMessages = this.chatMessages.filter(p => p.isSent && !p.visited);
          let chatIndex = 0;
          if (filterChatMessages.length > 0) {
            await this.sendAudioToApi(null, filterChatMessages[chatIndex].text, false, lectureNumber);
            filterChatMessages[chatIndex].visited = true;
          } else {
            if (lectureNumber < this.lectureParts.length) {
              this.currentLectureIndex = lectureNumber;
            }
            if (this.currentLectureIndex < this.lectureParts.length) {
              setTimeout(() => this.displayLectureModule(this.currentLectureIndex), 1500);
            } else {
              this.endCourse();
            }
          }

        }
      }
    };
  }

  playQueryResponse(queryAudio: string, isForIntro: boolean, isForBrief: boolean, isChatDoubt: boolean, lectureNumber: number): void {
    if (!queryAudio) {
      this.aiTalk = false;
      this.isQueryFinished = true;
      return;
    }
    if (this.queryAudioElement) {
      this.queryAudioElement.pause();
      this.queryAudioElement.src = ""; // Clear the source to free memory
      this.queryAudioElement = null;
    }
  
    console.log("Query Audio path - " + queryAudio);
    this.queryAudioElement = new Audio(queryAudio);
    this.pauseLecture();
    this.isQueryFinished = false;
    this.aiTalk = true;
    this.cdr.detectChanges();
  
    this.queryAudioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
      this.aiTalk = false;
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    });
  
    this.queryAudioElement.onended = () => {
      this.aiTalk = false;
      this.isQueryFinished = true;
      if (isForIntro) {
        this.playLectureBrief();
      } else if (isForBrief) {
        this.displayLectureModule(this.currentLectureIndex);
      } else if (isChatDoubt) {
        const sessionPause = "assets/audio/let_move_to_next_question.mp3";
        this.replyResponse(sessionPause, false, lectureNumber);
      }
  
      this.queryAudioElement = null;
      this.cdr.detectChanges();
    };
  }

  async playLectureBrief(): Promise<void> {
    const response = await fetch(
      `${environment.AWS_MICRO_SERVICE_API}lecture_start_instruction`,
      { method: 'POST' }
    );

    if (response.ok) {
      const data = await response.json();
      this.queryAudio = environment.AWS_MICRO_SERVICE_API + data.audio_url || '';
      this.isProcessing = false;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
      setTimeout(() => this.playQueryResponse(this.queryAudio, false, true, false, 0), 1500);
    }

  }

  pauseQueryResponse() {
    if (this.queryAudioElement && !this.queryAudioElement.paused) {
      this.queryAudioElement.pause();
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    }
  }

  pauseLecture(): void {
    if (this.lectureAudioElement) {
      this.lecturePausedTime = this.lectureAudioElement.currentTime;
      this.lectureAudioElement.pause();
      this.aiTalk = false;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
    }
  }


  private updateLoaderMessage(loaderMessages: string[]): void {
    this.loaderTextIndex = 0;
    this.loaderMessage = loaderMessages[this.loaderTextIndex];
    this.cdr.detectChanges();

    if (this.loaderInterval) {
      clearInterval(this.loaderInterval);  // Clear previous interval
    }

    this.loaderInterval = setInterval(() => {
      if (this.isProcessing) {
        this.loaderTextIndex = (this.loaderTextIndex + 1) % loaderMessages.length;
        this.loaderMessage = loaderMessages[this.loaderTextIndex];
        this.cdr.detectChanges();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.loaderInterval) clearInterval(this.loaderInterval);
  }

  sendMessage(): void {
    if (this.userMessage && this.userMessage.trim()) {
      this.chatMessages.push({
        text: this.userMessage.trim(),
        type: "text",
        isSent: true,
        replied: false,
        visited: false
      });

      this.userMessage = '';
      this.scrollToBottom();
    }
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.chatChannel) {
        this.chatChannel.nativeElement.scrollTop = this.chatChannel.nativeElement.scrollHeight;
      }
    }, 0);
  }
}
