import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { PageService } from 'src/app/service/page.service';


@Component({
  selector: 'app-webinarlist',
  templateUrl: './webinarlist.component.html',
  styleUrls: ['./webinarlist.component.css']
})
export class WebinarlistComponent implements OnInit {
  webinar: any[];
  imagesList: any[] = [ 'assets/blue.png', 'assets/grey.png', 'assets/light-blue.png', 'assets/light-yellow.png'];
  constructor(private pageService: PageService,private courseServer: CourseService) { }

  ngOnInit() {
    // this.courseServer.getWebinar().subscribe(Response => {
    //   this.webinar = Response;
    // });
    this.pageService.Webinarlist({"Current_Date" : new Date()}).subscribe(Response => {
      if(Response && Response.length > 0){
        var webinars = Response.sort((a,b) => b.Webinar_Date_Time.localeCompare(a.Webinar_Date_Time));;
        this.webinar = webinars.splice(0,4)
        
      }
    });
  }

}
