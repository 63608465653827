import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TrainerformComponent } from 'src/app/pages/trainer/trainerform/trainerform.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor( private dialog: MatDialog) { }

  ngOnInit() {
  }

  openLogin(): void {
    const dialogRef = this.dialog.open(TrainerformComponent, {
      width: '620px',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    // dialogRef.close();
  }
}
