import { Component, OnInit, Inject, Sanitizer } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { EnrollCourse } from 'src/app/course/enroll';
import { AuthService } from 'src/app/service/auth.service';
import { CourseService } from 'src/app/service/course.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { ForumDialogComponent } from 'src/app/studentdashboard/forum-dialog/forum-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  status: any[];
  youtube = false;
  forum = new FormGroup({
    query: new FormControl()
  });
  noteForm = new FormGroup({
    Notes: new FormControl('', Validators.required)
  });
  supportForm = new FormGroup({
    Support_Type: new FormControl('', Validators.required),
    Question: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required)
  });
  course: EnrollCourse;
  message: string;
  enrollCourseList: any[];
  courseCurriculam: any;
  Conversation: any[];
  seektime: number;
  setTime: number;
  sticky: boolean;
  Start_Splited_Array: string[];
  End_Splited_Array: string[];
  NoteList: any;
  foramData: any[];

  constructor(private rout: ActivatedRoute, private courseService: CourseService, private spinner: NgxSpinnerService,
    private authService: AuthService, private dashBoardService: DashboardService,
    public dialog: MatDialog, @Inject(DOCUMENT) document) { }

  ngOnInit() {
    // setTimeout(() => {
    //     this.dialog.open(VideoDialogComponent, {
    //       width: '600px'
    //     });
    //   }, 4000);
    // this.dashBoardService.getForam().subscribe(Response => {
    //   console.log('forum response', Response);
    // });
    this.dashBoardService.findNote().subscribe(Response => {
      this.NoteList = Response;

    });
    this.dashBoardService.getForam().subscribe(Response => {
    });

    this.sticky = false;
    this.rout.queryParams.subscribe(params => {
      this.dashBoardService.enrollCourse().subscribe(Response => {
        this.course = Response.find(res => res._id === params.id);
        this.courseCurriculam = this.course.Course_Curriculam;
        this.course.Course_Video = (this.courseCurriculam[0].Section_Course_Video) ? this.courseCurriculam[0].Section_Course_Video : '';
        if (this.course.Course_Video.includes("embed")) {
          this.youtube = true;
          if (this.course.Course_Video.includes("youtube")) {
            this.course.Course_Video = this.course.Course_Video.replace('youtube', 'youtube-nocookie');
          }
          this.course.Course_Video = this.course.Course_Video + "?rel=0&amp;controls=1&amp;modestbranding=1&amp;autoplay=1";
        }
        this.Conversation = this.course.CourseForum;
        this.status = this.course.Status;
        this.course.Course_Curriculam.forEach((element, index) => {

          if (element.Topics && element.Topics.length > 0) {
            element.Topics.forEach((subelement, subindex) => {
              this.Start_Splited_Array = subelement.Topic_Start_Time.split(':');
              const HRS: string = this.Start_Splited_Array.length === 3 ? this.Start_Splited_Array[0] : '0';
              const Min: string = this.Start_Splited_Array.length === 3 ? this.Start_Splited_Array[1] : this.Start_Splited_Array[0];
              const Sec: string = this.Start_Splited_Array.length === 3 ? this.Start_Splited_Array[2] : this.Start_Splited_Array[1];
              // tslint:disable-next-line: radix
              const Start_Time: number = Math.floor(parseInt(HRS) * 60 * 60) + Math.floor(parseInt(Min) * 60)
                // tslint:disable-next-line: radix
                + Math.floor(parseInt(Sec));
              this.course.Course_Curriculam[index][`Topics`][subindex][`Topic_Start_Time`] = Start_Time;
              this.End_Splited_Array = subelement.Topic_End_Time.split(':');
              const HRS1: string = this.End_Splited_Array.length === 3 ? this.End_Splited_Array[0] : '0';
              const Min1: string = this.End_Splited_Array.length === 3 ? this.End_Splited_Array[1] : this.End_Splited_Array[0];
              const Sec1: string = this.End_Splited_Array.length === 3 ? this.End_Splited_Array[2] : this.End_Splited_Array[1];
              const End_Time: number = Math.floor(parseInt(HRS1) * 60 * 60) +
                Math.floor(parseInt(Min1) * 60) + Math.floor(parseInt(Sec1));
              this.course.Course_Curriculam[index][`Topics`][subindex][`Topic_End_Time`] = End_Time
            });
          }
        });
      });
    }
    );
  }


  captureScreen() {
    const data = this.NoteList.map(res => {
      return `${formatDate(new Date(res.Updated_On), 'MMM d', 'en')}: ${res.Note} `;
    });
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', 'MyNotes');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  playSeek($event, videos) {
    this.seektime = $event.target.dataset.starttime;
    videos.currentTime = this.seektime;
  }
  videoUpdate(videos) {
    this.seektime = videos.currentTime;
  }
  onChangeStatus(sid, tid) {
    const data = {
      Course_Id: this.course.Course_Id,
      Section_Id: sid,
      Topic_Id: tid
    };
    this.courseService.updateVideoStatus(data).subscribe(Response => {
      this.status = Response[`Status`];
    });
  }
  onLogout() {
    this.authService.logOut().subscribe(Response => {
    });
  }

  submitForum() {
    const forumData = {
      User_Id: this.course.User_Id,
      User_Type: 'User',
      Course_Id: this.course.Course_Id,
      Query: this.forum.value.query,
    };
    this.courseService.Submitforum(forumData).subscribe(Response => {
      this.foramData = Response[0].Conversation;
      this.Conversation = Response;
    });
    this.forum.reset();
  }
  openForum(forumId) {
    this.dialog.open(ForumDialogComponent, {
      data: {
        fid: forumId
      }
    });
  }
  submitSupport() {
    const formData = {
      Course_Id: this.course.Course_Id,
      Support_Type: this.supportForm.value.Support_Type,
      Question: this.supportForm.value.Question,
      Description: this.supportForm.value.Description,
    };
    this.spinner.show();
    this.courseService.submitTicket(formData).subscribe(Response => {
      if (Response[`success`]) {
        this.message = `Successfully Generated Query, your token No. is ` + Response[`response`].Ticket_Id;
      }
      this.supportForm.reset();
      this.spinner.hide();
    });
  }
  stickyBtn(sticky) {
    if (sticky === true) {
      this.sticky = false;
    } else {
      this.sticky = true;
    }
  }
  addNote() {
    const note = {
      Notes: this.noteForm.value.Notes
    };
    this.spinner.show();
    this.dashBoardService.addNote(note).subscribe(Response => {

    });
    this.dashBoardService.findNote().subscribe(Response => {
      this.NoteList = Response[`Notes`];
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    this.noteForm.reset();
  }

  deleteNote(nid) {
    this.spinner.show();
    this.dashBoardService.deleteNote(nid).subscribe(Response => {
      this.spinner.hide();
      this.NoteList = Response[`Notes`];
    });
  }

  assignVideo(videoUrl) {
    if (videoUrl.includes("embed")) {
      this.youtube = true;
      if (videoUrl.includes("youtube")) {
        this.course.Course_Video = videoUrl.replace('youtube', 'youtube-nocookie');
      }
      this.course.Course_Video = this.course.Course_Video + "?rel=0&amp;controls=1&amp;modestbranding=1&amp;autoplay=1";
    }
    else {
      this.youtube = false;
      this.course.Course_Video = videoUrl;
    }
  }

}
