import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Jobs } from '../pages/jobs/jobs';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient, private AuthService: AuthService) { }

  getJobsList(): Observable<Jobs[]> {
    return this.http.post<Jobs>(environment.MICRO_SERVICE_API + 'joblisting/clientgetjoblistings', {}).pipe(map(res => {
      return res[`response`];

    }));
  }
  getJobsDetails(id): Observable<Jobs> {
    return this.http.post<any>( environment.MICRO_SERVICE_API + 'joblisting/clientindividualjob ', { Job_Id: id}).pipe(map(res => {
      return res;
    }));
  }
  PostResume(FormData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'joblisting/applynow ', FormData).pipe(map(res => {
      if (res[`clearCookie`]) {
        this.AuthService.logOut();
      } else {
        return res[`response`];
      }
    }));
  }
}
