import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

            if(!req.url.includes("login") || req.url.includes("login/logout")) {
                const authToken = this.authService.getAuthToken();
                req = req.clone({
                    setHeaders:
                        { Authorization: authToken }
                    }
                );
            }
            return next.handle(req);
    }
}
