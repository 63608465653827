import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { CourseService } from 'src/app/service/course.service';
import { Course } from '../course';
import { Seo } from '../courseseo';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'all-course',
  templateUrl: './all-course.component.html',
  styleUrls: ['./all-course.component.css']
})
export class AllCourseComponent implements OnInit {
  courseList: Course[];
  allCourseList: Course[];
  allCourse = 'Category';
  courseCategoryList: any[];
  recentAddedCourse: any[];
  pageSeo: Seo;
  CourseId: string;
  course: any;
  Average_Rating: string = "3";
  Total_Reviewers: number = 0;
  Five_Star: number = 0;
  Four_Star: number = 0;
  Three_Star: number = 0;
  Two_Star: number = 0;
  One_Star: number = 0;
  review: any;
  check: boolean;
  Course_Category_URL: any;
  CourseCategoryURL: string;
  Coursecategory: any;
  Catogaries: any;
  Course_Category: any;
  isLoaderPresent = true;
  constructor(private rout: ActivatedRoute, private courseService: CourseService, private route: ActivatedRoute, private title: Title, private meta: Meta, private router: Router, private spinner: NgxSpinnerService) { }

  selectCategory(Category) {
    const category = Category.value;
    this.spinner.show();
    if (this.allCourse === category) {
      this.courseService.allCourseList().subscribe(Response => {
        this.allCourseList = Response;
        if (this.allCourseList.length > 0) {
          this.check = true;
          let rating = 0;
          this.allCourseList.forEach(course => {
            rating = 0;
            if (course && course.CourseReview && course.CourseReview.length > 0) {
              course.CourseReview.forEach(courseReview => {
                rating += courseReview.Review_Number;
              })
              rating = rating / course.CourseReview.length;
              course.rating = Number((rating).toFixed(1));
            }
            else {
              course.rating = 0;
            }
          })
          this.spinner.hide();
        } else {
          this.check = false;
          this.spinner.hide();
        }

      });
    } else {
      this.courseService.courseSearch(category).subscribe(Response => {
        this.allCourseList = Response;
        if (this.allCourseList.length > 0) {
          this.check = true;
          let rating = 0;
          this.allCourseList.forEach(course => {
            rating = 0;
            if (course && course.CourseReview && course.CourseReview.length > 0) {
              course.CourseReview.forEach(courseReview => {
                rating += courseReview.Review_Number;
              })
              rating = rating / course.CourseReview.length;
              course.rating = Number((rating).toFixed(1));
            }
            else {
              course.rating = 0;
            }
          })
          this.spinner.hide();
        } else {
          this.check = false;
          this.spinner.hide();
        }
      });

    }
  }

  ngOnInit() {
    this.spinner.show();

    this.courseService.getPageSeo('Course_Category').subscribe(Response => {
      this.pageSeo = Response;
      if (typeof (this.pageSeo) !== 'undefined') {
        this.meta.updateTag({ name: 'description', content: this.pageSeo.Meta_Description });
        this.meta.updateTag({ name: 'keywords', content: this.pageSeo.Meta_Keywords });
        this.meta.updateTag({ name: 'abstract', content: this.pageSeo.Meta_Abstract });
        this.meta.updateTag({ name: 'Author', content: this.pageSeo.Meta_Author });
        this.meta.updateTag({ name: 'copyright', content: this.pageSeo.Meta_Copyright });
        this.meta.updateTag({ name: 'Distribution', content: this.pageSeo.Meta_Distribution });

        this.meta.updateTag({ itemprop: 'name', content: this.pageSeo.Meta_Item_Prop_Name });
        this.meta.updateTag({ itemprop: 'image', content: this.pageSeo.Meta_Item_Prop_Image });
        this.meta.updateTag({ itemprop: 'description', content: this.pageSeo.Meta_Item_Prop_Description });
        this.meta.updateTag({ property: 'article:author', content: this.pageSeo.Meta_Property_Article_Author });
        this.meta.updateTag({ property: 'article:publisher', content: this.pageSeo.Meta_Property_Article_Publisher });


        this.meta.updateTag({ property: 'og:type', content: this.pageSeo.Meta_Property_OG_Type });
        this.meta.updateTag({ property: 'og:url', content: this.pageSeo.Meta_Property_OG_URL });
        this.meta.updateTag({ property: 'og:site_name', content: this.pageSeo.Meta_Property_OG_SiteName });
        this.meta.updateTag({ property: 'og:description', content: this.pageSeo.Meta_Property_OG_Description });
        this.meta.updateTag({ property: 'og:title', content: this.pageSeo.Meta_Property_OG_Title });
        this.meta.updateTag({ property: 'og:author', content: this.pageSeo.Meta_Property_OG_Author });
        this.meta.updateTag({ property: 'og:image', content: this.pageSeo.Meta_Property_OG_Image });
      }

    });
    this.courseService.courseCategoryList().subscribe(Response => {
      this.courseCategoryList = Response;
    });
    this.courseService.allCourseList().subscribe(Response => {
      this.allCourseList = Response;
      if (this.allCourseList.length > 0) {
        this.check = true;
        let rating = 0;
        this.allCourseList.forEach(course => {
          rating = 0;
          if (course && course.CourseReview && course.CourseReview.length > 0) {
            course.CourseReview.forEach(courseReview => {
              rating += courseReview.Review_Number;
            })
            rating = rating / course.CourseReview.length;
            course.rating = Number((rating).toFixed(1));
          }
          else {
            course.rating = 0;
          }
        })
        this.spinner.hide();
      } else {
        this.check = false;
        this.spinner.hide();
      }

    });

    this.courseService.recentAddedCourse().subscribe(Response => {
      this.recentAddedCourse = Response;
    });
    this.route.params.subscribe(
      (prams: Params) => {
        // tslint:disable-next-line: align
        if (Object.keys(prams).length === 1) {
          this.courseService.getCategoryIdDetails(prams[`Category`]).subscribe(Response => {
            this.courseList = Response;
            this.title.setTitle(prams[`Category`]);
          });
        } else {
          this.courseService.getCourseCategoryId(prams[`courseCategory`]).subscribe(Response => {
            this.courseList = Response;
            if (this.courseList == undefined) {
              this.router.navigateByUrl('/error/')
            }
            this.title.setTitle(prams[`courseCategory`]);
          }
          );
        }
      });
  }

  public isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
}
