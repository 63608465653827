import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { MustMatch } from 'src/app/webinarform/must-match.validator';
import { PageService } from 'src/app/service/page.service';
import { TransfereService } from '../service/data-transfer';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-webinarform',
  templateUrl: './webinarform.component.html',
  styleUrls: ['./webinarform.component.css']
})
export class WebinarformComponent implements OnInit {
  webinarform = true;
  submitted = false;
  loading: boolean;
  name = new FormControl('');
  webinarformarr: any[];
  Webinar_Data: any[];
  signupSuccess = false;
  forgotPassword: boolean;
  emailExit: boolean;
  existEmail: string;
  registerForm: FormGroup;
  webinarformid: any[];
  dataValue: string;
  error: string;
  message: string;


  constructor(private cookieService: CookieService, private dialogRef: MatDialogRef<WebinarformComponent>, private snackBar: MatSnackBar, private transferservice: TransfereService, private formBuilder: FormBuilder, private pageService: PageService, private authService: AuthService, ) { }

  ngOnInit() {


    this.registerForm = this.formBuilder.group({
      firstName: [undefined, Validators.required],
      email: [undefined, [Validators.required, Validators.email]],
      phone: [undefined, Validators.required],

    });
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
if(this.transferservice.data) {
    var WebinarData = {
      Webinar_Id: this.transferservice.data._id,
      Name: this.registerForm.value.firstName,
      Email: this.registerForm.value.email,
      Phone_Number: this.registerForm.value.phone
    };
  }

    this.pageService.Webinarregister(WebinarData).subscribe(Response => {
      this.Webinar_Data = Response;
      this.dialogRef.close();

      if (!Response) {
        this.snackBar.open('Failed To Register Try Again Lated', this.registerForm.value.firstName, { duration: 10000 });
      } else {
        this.snackBar.open('Thanks! We Callback Soon..', this.registerForm.value.firstName, { duration: 5000 });
      }

    });
  }

}
