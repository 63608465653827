import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { PageService } from 'src/app/service/page.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactMode: string;
  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    mobile: new FormControl('', Validators.required),
    query: new FormControl(),

  });
  constructor( private pageService: PageService, private snackBar: MatSnackBar, private title: Title) {

  }

  ngOnInit() {
    this.title.setTitle('Contact Us');
    this.contactMode = 'bangalore';
  }
  enrollMap() {
    window.scrollTo({
        top: 750,
        left: 0,
        behavior: 'smooth'
      });
  }
  submitContact() {
    const formData = {
      Name: this.contactForm.value.name,
      Email: this.contactForm.value.email,
      Phone_Number: this.contactForm.value.mobile,
      Query : this.contactForm.value.query,
      User_Type : 'user',
    };
    this.pageService.postFormData(formData).subscribe(Response => {
      this.snackBar.open( 'Message Send', formData.Name, {
        duration: 2000,
      });
      this.contactForm.reset();
    });

  }
}
