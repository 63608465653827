import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { Seo } from 'src/app/course/courseseo';
import { Title, Meta } from '@angular/platform-browser';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  projectList: any[];
  projectCategory: any[];
  pageSeo: Seo;
  constructor(private courseService: CourseService, private pageService: PageService, private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.courseService.getPageSeo('Projects').subscribe( Response => {
      this.pageSeo = Response;
      this.title.setTitle('Project');
      if (typeof(this.pageSeo) !== 'undefined') {
        this.meta.updateTag({name: 'description', content: this.pageSeo.Meta_Description});
        this.meta.updateTag({name: 'keywords', content: this.pageSeo.Meta_Keywords});
        this.meta.updateTag({name: 'abstract', content: this.pageSeo.Meta_Abstract });
        this.meta.updateTag({name: 'Author', content: this.pageSeo.Meta_Author });
        this.meta.updateTag({name: 'copyright', content: this.pageSeo.Meta_Copyright });
        this.meta.updateTag({name: 'Distribution', content: this.pageSeo.Meta_Distribution });

        this.meta.updateTag({itemprop: 'name', content: this.pageSeo.Meta_Item_Prop_Name  });
        this.meta.updateTag({itemprop: 'image', content: this.pageSeo.Meta_Item_Prop_Image  });
        this.meta.updateTag({itemprop: 'description', content: this.pageSeo.Meta_Item_Prop_Description });
        this.meta.updateTag({property: 'article:author', content: this.pageSeo.Meta_Property_Article_Author });
        this.meta.updateTag({property: 'article:publisher', content: this.pageSeo.Meta_Property_Article_Publisher });


        this.meta.updateTag({property: 'og:type', content: this.pageSeo.Meta_Property_OG_Type });
        this.meta.updateTag({property: 'og:url', content: this.pageSeo.Meta_Property_OG_URL });
        this.meta.updateTag({property: 'og:site_name', content: this.pageSeo.Meta_Property_OG_SiteName });
        this.meta.updateTag({property: 'og:description', content: this.pageSeo.Meta_Property_OG_Description });
        this.meta.updateTag({property: 'og:title', content: this.pageSeo.Meta_Property_OG_Title });
        this.meta.updateTag({property: 'og:author', content: this.pageSeo.Meta_Property_OG_Author });
        this.meta.updateTag({property: 'og:image', content: this.pageSeo.Meta_Property_OG_Image });
      }
    });

    this.pageService.getProject().subscribe( Response => {
      this.projectList = Response[0].MiniProjects;
      this.projectCategory = Response;
    });
  }

}
