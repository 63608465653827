import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { CourseService } from 'src/app/service/course.service';
import { MatDialog, MatDialogConfig, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  trending: any[];
  orderList: any[];
  private sum=0;  
 private value;  
  closeResult: string;
  constructor(private modalService: NgbModal,private courseServer: CourseService, private dashboardService: DashboardService) { }

  ngOnInit() {
    this.courseServer.recentAddedCourse().subscribe( Response => {
      this.trending = Response;
     
    });
    
    this.dashboardService.myOrders().subscribe( Response => {
      this.orderList = Response;
     
      
    });
  }
  
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    // if(this.Average_Rating=='0'){
    //   console.log("test")
    //   document.getElementById('review-model-popup').style.pointerEvents = "none";
    // }

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

}
