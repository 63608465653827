import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/service/course.service';
import { PageService } from 'src/app/service/page.service';


export interface ResultData {
  Date: string;
  Name: string;
  Course: string;
  Mobile_no: string;
  Status: string;
  Status2: string;
  Status3: string;
}
export interface Result {
Company_Id: string;
Company_Name: string;
Created_On: Date;
Title: string;
User_List: ResultData[];
}
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})

export class ResultComponent implements OnInit {
  dataSource: ResultData[];
  result: Result;
  displayedColumns: string[] = ['Date', 'Name', 'Mobile_no', 'Email_Id', 'Course', 'Status', 'Status2', 'Status3'];
  constructor(private rout: ActivatedRoute, private pageservice: PageService) {

  }

  ngOnInit() {
    this.rout.queryParams.subscribe( params => {
      const Params = params[`query`].split('_');
      const data = { Company_Name: Params[0], Title: Params[1], Unique_Id: Params[2] };
      this.pageservice.getResult(data).subscribe( Response => {
        this.result = Response;
        this.dataSource = Response.User_List;

      });
  });
}

}
