import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-jobapplied',
  templateUrl: './jobapplied.component.html',
  styleUrls: ['./jobapplied.component.css']
})
export class JobappliedComponent implements OnInit {
  joblist: any[];
  constructor(private dashboardServer: DashboardService) { }

  ngOnInit() {
    this.dashboardServer.getJobList().subscribe( Response => {
      this.joblist = Response;
    });
  }

}
