import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Course } from '../course/course';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CourseService {
  coursecategory: any[];

  constructor(private http: HttpClient) { }

   getCategories() {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcategorydetails',{}).pipe(map(res => {
      return res[`response`];

    }));
  }

  getCourseDetails(course: string) {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursedetails', {Course_URL: course}).pipe(map(res => {
      // tslint:disable-next-line: no-string-literal
      return res['response'];
    }));
  }
  getCategoryIdDetails(CategoryURL) {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursecategoryonid', {Category_URL: CategoryURL}
     ).pipe(map(res => {
      // tslint:disable-next-line: no-string-literal
      return res['response'];
    }));
  }
  getCourseCategoryId(CourseCategoryURL) {
    // tslint:disable-next-line: max-line-length
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursecategorydetails', {Course_Category_URL: CourseCategoryURL}).pipe(map(res => {
      // tslint:disable-next-line: no-string-literal
      return res['response'];
    }));
  }

  getWebinar() {
    return this.http.post( environment.MICRO_SERVICE_API + 'schedules/clientgetschedules', {}).pipe(map(res => {
      return res[`response`];
    }));
  }
  clientgethomecourse() {
    return this.http.post( environment.MICRO_SERVICE_API + 'banners/clientgethomecourse ', {}).pipe(map(res => {
      return res[`response`];
    }));
  }
  selfPaced() {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursebasedontype',
     {Course_Type: ['Online']}).pipe(map(res => {
      return res[`response`];
    }));
  }
  allCourseList() {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursebasedontype',
     {Course_Type: ['Online', 'Webinar', 'Classroom']}).pipe(map(res => {
      return res[`response`];
    }));
  }

  courseSearch(CourseCategoryId) {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursebasedoncategoryid', {Course_Category_Id: CourseCategoryId}).pipe(map(res => {
      return res[`response`];
    }));
  }
  courseCategoryList() {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientgetcoursecategory ', {}).pipe(map(res => {
      return res[`response`];
    }));
  }
  recentAddedCourse() {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientrecentlyadded', {})
      .pipe(map(res => {
        return res[`response`];
    }));
  }
  review(Course_Id) {
    return this.http.post( environment.MICRO_SERVICE_API + 'coursereview/clientfindcoursereview', {"Course_Id": Course_Id})
      .pipe(map(res => {
        return res[`response`];
    }));
  }
  reletedCourse(courseUrl) {
    return this.http.post( environment.MICRO_SERVICE_API + 'course/clientrelatedcourse', { Course_URL: courseUrl})
      .pipe(map(res => {
        return res[`Courses`];
    }));
  }
  Submitforum(forumData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'courseforum/addcourseforum', forumData ).pipe(map(res => {
      return res[`response`];
    }));
  }
  reviewData(forumData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'coursereview/addcoursereview', forumData ).pipe(map(res => {
      return res[`response`];
    }));
  }

  submitTicket(formdata) {
    return this.http.post( environment.MICRO_SERVICE_API + 'support/submitticket', formdata ).pipe(map(res => {
      return res;
    }));
  }

  getTicketList() {
    return this.http.post( environment.MICRO_SERVICE_API + 'support/ticketlist', {} ).pipe(map(res => {
      return res[`response`];
    }));
  }
  replyTicket(formData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'support/replyticket', formData ).pipe(map(res => {
      return res[`response`];
    }));
  }
  getPageSeo(data) {
    return this.http.post( environment.MICRO_SERVICE_API + 'courseseo/getpageseo', {Page_Name: data} ).pipe(map(res => {
      return res[`response`][`0`];
    }));
  }
  updateVideoStatus(data) {
    return this.http.post( environment.MICRO_SERVICE_API + 'mycourses/updatesection', data ).pipe(map(res => {
      return res[`response`];
    }));
  }
}
