import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { CookieService } from 'ngx-cookie-service';
declare const gapi: any;
declare const FB: any;

export interface DialogData {
  userName: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public auth2: any;
  loginForm = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });
  signUpform = new FormGroup({
    username: new FormControl('', [Validators.required]),
    mobile: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });
  verifyForm = new FormGroup({
    Email: new FormControl('', [Validators.email, Validators.required])
  });
  // tslint:disable-next-line: no-input-rename
  isLoginMode = true;
  loading: boolean;
  signupSuccess = false;
  forgotPassword: boolean;
  emailExit: boolean;
  message: string;
  existEmail: string;
  constructor(public dialogRef: MatDialogRef<LoginComponent>, private authService: AuthService,
    private router: Router, private cookieService: CookieService) {
  }
  onSwitchLogin() {
    this.isLoginMode = !this.isLoginMode;
  }
  onSignUp() {
    const signUpData = {
      Name: this.signUpform.value.username,
      Email: this.signUpform.value.email,
      Password: this.signUpform.value.password,
      Phone_Number: this.signUpform.value.mobile,
    };
    this.loading = true;
    this.authService.signUp(signUpData).subscribe(resData => {
      if (resData[`success`]) {
        this.signupSuccess = false;
        this.loading = false;
      } else {
        this.existEmail = 'Email is already exists';
        this.signupSuccess = false;
        this.loading = false;
      }
    },
      error => {
        this.loading = false;
      });
    this.signUpform.reset();
  }

  login() {
    const userName = this.loginForm.value.userName;
    const password = this.loginForm.value.password;
    this.loading = true;
    this.authService.login(userName, password).subscribe(resData => {
      if (resData[`success`]) {
        if (resData[`Token`]) {
          this.loading = false;
          localStorage.setItem('auth', resData[`Token`]);
          this.dialogRef.close();
          this.authService.autologin();

        } else {
          this.loading = false;
        }
      } else {
        this.message = 'Authentication Failed';
        this.loading = false;
      }
    },
      error => {
        this.loading = false;
      });
    this.loginForm.reset();
  }


  onForgot() {
    this.dialogRef.close();
    this.router.navigate(['/forgotpassword']);
  }

  submitLogin() {
    this.loading = true;
    FB.login(response => {

      const { authResponse: { accessToken, userID } } = response;

      FB.api('/me', { fields: 'name, email,picture' }, res => {
        const { name, email, picture } = res;

        this.authService.facebookLogin({ accessToken, userID, name, email, picture }).subscribe(response => {
          if (response[`success`]) {
            if (response[`Token`]) {
              localStorage.setItem('auth', response[`Token`]);
              this.dialogRef.close({ data: true });
              this.authService.autologin();
              this.loading = false;

            } else {


            }
          }
        });
      });
    });
    return false;
  }

  public googleInit() {

    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '899032335916-vcq62as1hube3qkobmefip7e61suhepf.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });

  }
  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        const User_Id = profile.getId();
        const Name = profile.getName();
        const Email = profile.getEmail();
        const Photo = profile.getImageUrl();
        this.loading = true;
        this.authService.googleLogin({ User_Id, Name, Email, Photo }).subscribe(response => {
          if (response[`success`]) {
            if (response[`Token`]) {
              localStorage.setItem('auth', response[`Token`]);
              // this.dialogRef.close({data: true});
              this.dialogRef.close();
              this.authService.autologin();
              this.loading = false;

            } else {

              this.loading = false;

            }
          } else {
            this.message = 'Authentication Failed';
            this.loading = false;

          }
        });
      }, (error) => {
        // alert(JSON.stringify(error, undefined, 2));
        this.loading = false;
      });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.googleInit();
  }

  ngOnInit() {
    this.loading = false;
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '2248323492124365',
        cookie: true,
        xfbml: true,
        version: 'v4.0'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }

  goToTermsPage() {
    this.dialogRef.close();
    this.router.navigate(['/term']);
  }

}
