import { Component, OnInit, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { CourseService } from 'src/app/service/course.service';
import { EnquiryDialogComponent } from 'src/app/pages/enquiry-dialog/enquiry-dialog.component';
import { WindowRefService } from 'src/app/service/window.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  Catogaries: any;
  isSticky: boolean;
  isAuthenticate: boolean;
  navCheck: boolean;
  private userSubs: Subscription;
  CourseCategoryURL: string;
  Coursecategory: string;
  Course_Category_URL:string;
 
  constructor(public dialog: MatDialog, private rout: ActivatedRoute,private router: Router, private authService: AuthService, private courseService: CourseService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: any, private windowRefService: WindowRefService) {
  }

   openLogin(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '620px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof(result) === 'undefined') {
      } else {
        this.isAuthenticate = result.data;
      }
    });
  }

 ngOnInit() {
      this.isAuthenticate = this.authService.isLogin();
      this.isSticky = false;
      this.navCheck = false;
      if (this.isAuthenticate === false) {
      //if (isPlatformBrowser(this.platformId)) {
      //  this.windowRefService.nativeWindow.setTimeout(() => {
      //    this.dialog.open(EnquiryDialogComponent, {
      //      width: '600px'
      //    });
      //  }, 10000);
      //}
    }

      this.authService.user.subscribe(user => {
    this.isAuthenticate = !!user;

   });
      this.courseService.getCategories().subscribe( res => {
           this.Catogaries = res;
           
          //  let Course_Category = res[0].CourseCategory;
          //  console.log(Course_Category)
          //  if(Course_Category == undefined){
          //   this.router.navigateByUrl('/error/')  
          // }
          //  for (const key in Course_Category) {
          //   if (Course_Category.hasOwnProperty(key)) {
          //     const element = Course_Category[key];
          //     if(element.Course_Category_URL==undefined){
          //       this.router.navigateByUrl('/error/')
          //     }
          //     console.log(element);
          //   }
          // }
            
   });
 }
 responsiveMenu() {
  this.navCheck = !this.navCheck;
 }

 @HostListener('window:scroll', ['$event'])
 checkScroll() {
   this.isSticky = window.pageYOffset >= 400;
 }

}
