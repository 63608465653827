import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WebinarformComponent } from './webinarform.component';
import { FooterModule } from '../layout/footer/footer.module';


@NgModule({
  declarations: [WebinarformComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FooterModule
  ],
})
export class WebinarformModule { }
