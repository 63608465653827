import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface Forum {
  Question: string;
  LastUpdated: Date;
}
@Component({
  selector: 'app-forum-dialog',
  templateUrl: './forum-dialog.component.html',
  styleUrls: ['./forum-dialog.component.css']
})
export class ForumDialogComponent implements OnInit {
  foramData: any[];
  isloading = false;
  replyForm = new FormGroup({
    Query: new FormControl('', Validators.required)
  });
  forumId: string;
  forum: Forum;
  conversationArr: any[];
  constructor(private dashboardService: DashboardService, @Inject(MAT_DIALOG_DATA) data: any) {
    this.forumId = data.fid;
  }

  ngOnInit() {
    this.isloading = true;
    this.dashboardService.getForam().subscribe(Response => {
      this.isloading = false;
      this.forum = Response.find( res => res._id === this.forumId);
      this.conversationArr = this.forum[`Conversation`];
      // console.log(this.forum);
      // console.log( this.conversationArr);
    });
  }
  onReply() {
    const replyData = {
      Forum_Id: this.forumId,
      User_Type: 'User',
      Query: this.replyForm.value.Query
    };
    this.isloading = true;
    this.dashboardService.replyForam(replyData).subscribe(Response => {
      this.isloading = false;
      this.forum = Response.find( res => res._id === this.forumId);
      this.conversationArr = this.forum[`Conversation`];
      this.replyForm.reset();
    });

  }
}
