import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRouting } from './pages.routing';
// page import
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebinarlistComponent } from '../course/webinarlist/webinarlist.component';
import { OfferComponent } from '../shared/offer/offer.component';
import { AngularMaterialModule } from '../shared/angular-material/angular-material.module';
import { ProjectComponent } from './project/project.component';
import { CartComponent } from './cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '../auth/auth.module';
import { LoginComponent } from '../auth/login/login.component';
import { FaqComponent } from './faq/faq.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { TrainerComponent } from './trainer/trainer.component';
import { VerifyComponent } from './verify/verify.component';
import { ResultComponent } from './result/result.component';
import { EnquiryDialogComponent } from './enquiry-dialog/enquiry-dialog.component';
import { TrainerdetailComponent } from './trainer/trainerdetail/trainerdetail.component';
import { TrainerformComponent } from './trainer/trainerform/trainerform.component';
import { JobformComponent } from './jobs/jobform/jobform.component';
import { ProjectDetailsComponent } from './project/project-details/project-details.component';
import { VideoComponent } from './video/video.component';
import { ForumComponent } from './forum/forum.component';
import { ForumdetailsComponent } from './forum/forumdetails/forumdetails.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { OnlineCourseModule } from '../course/onlinecourse/online-course.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SafePipe } from './video/sanitizer.pipe';
import { HomeModule } from './home/home.module';
import { JobModule } from './jobs/jobs.module';
import { FooterModule } from '../layout/footer/footer.module';
import { PagesComponent } from './pages.component';





@NgModule({
  declarations: [
    PagesComponent,
    AboutComponent,
    ContactComponent,
    PageNotFoundComponent,
    WebinarlistComponent,
    ProjectComponent,
    ProjectDetailsComponent,
    CartComponent,
    PaymentComponent,
    FaqComponent,
    TermConditionComponent,
    TrainerComponent,
    TrainerdetailComponent,
    TrainerformComponent,
    VerifyComponent,
    ResultComponent,
    EnquiryDialogComponent,
    VideoComponent,
    ForumComponent,
    ForumdetailsComponent,
    UserprofileComponent,
    SafePipe,
  ],
  imports: [

      PageRouting,
      CommonModule,
      AngularMaterialModule,
      FormsModule,
      ReactiveFormsModule,
      AuthModule,
      OnlineCourseModule,
      NgxSpinnerModule,
      HomeModule,
      JobModule,
      FooterModule
     
  ],
  exports: [
    PageNotFoundComponent,
    WebinarlistComponent,
    PaymentComponent,
    VideoComponent,
    FooterModule
  ],
   // exports:[
  //   AboutComponent
  // ],
  // entryComponents: [AboutComponent],
   entryComponents: [JobformComponent, OfferComponent, LoginComponent, TrainerformComponent, EnquiryDialogComponent],
})
export class PagesModule { }
