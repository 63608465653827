import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CourseService } from 'src/app/service/course.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  reviewForm = new FormGroup ({
    rating: new FormControl(),
    review: new FormControl()
  });
  constructor( private courseService: CourseService) { }

  ngOnInit() {
  }
  submitReview() {
    const submitData = {
      Course_Id : '5d45797607b4a502e8031940' ,
      // tslint:disable-next-line: max-line-length
      Review_Message : this.reviewForm.value.review,
      Review_Number: this.reviewForm.value.rating
    };
    this.courseService.reviewData(submitData).subscribe( Response => {
    });
    this.reviewForm.reset();

  }
}
