import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-enquiry-dialog',
  templateUrl: './enquiry-dialog.component.html',
  styleUrls: ['./enquiry-dialog.component.css']
})
export class EnquiryDialogComponent implements OnInit {
  form = new FormGroup({
    Name: new FormControl('', Validators.required),
    Email: new FormControl('', [Validators.required, Validators.email], ),
    PhoneNo: new FormControl('', Validators.required),
    Query: new FormControl('', Validators.required)
  });
  constructor( private pageService: PageService, private dialogRef: MatDialogRef<EnquiryDialogComponent>,
               private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  submitQuery() {
    const formData = {
      Name: this.form.value.Name,
      Phone_Number: this.form.value.PhoneNo,
      Email: this.form.value.Email,
      Query: this.form.value.Query,
      User_Type: 'User',
    };
    this.pageService.postFormData(formData).subscribe( Response => {
      this.dialogRef.close();
      this.snackBar.open( 'Thanks! We Callback Soon..', this.form.value.Name, {
        duration: 3000,
      });

    });
  }
}
