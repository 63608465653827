import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Layout import
import { LayoutModule } from '@angular/cdk/layout';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { FooterComponent } from './layout/footer/footer.component';

// Module Imports

import { OnlineCourseModule } from './course/onlinecourse/online-course.module'
import { CourseModule } from '../app/course/course.module';
// import { OnlineCourseModule } from '../app/course/onlinecourse.module'
import { PagesModule } from './pages/pages.module';
import { StudentdashboardModule } from './studentdashboard/studentdashboard.module';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OfferComponent } from './shared/offer/offer.component';
import { AuthService } from './service/auth.service';
import { DashboardService } from './service/dashboard.service';
import { CourseService } from './service/course.service';
import { JobService } from './service/job.service';
import { AuthInterceptor } from './auth/auth-interceptor.service';

import { VideoDialogComponent } from './shared/video-dialog/video-dialog.component';
import { ForumDialogComponent } from './studentdashboard/forum-dialog/forum-dialog.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { from } from 'rxjs';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { WebinarformModule } from 'src/app/webinarform/webinarform.module'
import { WebinardetailComponent } from './webinardetail/webinardetail.component';

import { PaymentTestComponent } from './payment-test/payment-test.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { ShareModule } from '@ngx-share/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRouting } from './app.routing';
import { PrwatechAiModule } from './prwatech-ai/prwatech-ai.module';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    OfferComponent,
    VideoDialogComponent,
    DialogBoxComponent,
    WebinardetailComponent,
    PaymentTestComponent,
    WebinarsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StudentdashboardModule,
    PagesModule,
    CourseModule,
    AuthModule,
    ShareModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    AppRouting,
    NgxSpinnerModule,
    OnlineCourseModule,
    FontAwesomeModule,
    WebinarformModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    LazyLoadImageModule.forRoot({
      preset: scrollPreset
    }),
    LayoutModule,
    CarouselModule,
    PrwatechAiModule
  ],
  exports: [
    NavigationComponent
  ],
  providers: [CourseService, AuthService, DashboardService,
    CookieService, JobService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }],
  entryComponents: [VideoDialogComponent, ForumDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
