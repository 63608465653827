export class User {
  constructor(
    public authtoken: string,

  ) {}

  get token() {
    return this.authtoken;
  }
}
