import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/service/dashboard.service';
import { ActivatedRoute, Params } from '@angular/router';
import { PageService } from 'src/app/service/page.service';
import { AuthService } from 'src/app/service/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { MatDialog } from '@angular/material';
import { Forum } from 'src/app/pages/forum/forum'

@Component({
  selector: 'app-forumdetails',
  templateUrl: './forumdetails.component.html',
  styleUrls: ['./forumdetails.component.css']
})
export class ForumdetailsComponent implements OnInit {
  foramData: any[];
  isloading = false;
  replyForm = new FormGroup({
    Query: new FormControl('', Validators.required)
  });
  conversationArr: any[];
  forumId: string;
  forum: Forum;
  constructor(private dashboardService: DashboardService, private pageService: PageService,
     private route: ActivatedRoute, private authServer: AuthService, private dialog: MatDialog ) { }

  ngOnInit() {
    this.isloading = true;
    this.route.params.subscribe((param: Params) => {
      this.forumId = param.id;
      this.pageService.getForum({}).subscribe(Response => {
        this.isloading = false;
        this.forum = Response.find( res => res._id === param.id);
        this.conversationArr = this.forum[`Conversation`];
        // this.conversationArr.sort((a: Date, b: Date) => {
        //   return new Date(b[`CreatedOn`]) - new Date(a[`CreatedOn`]);
        // });
      });
    });

  }
  onReply(id) {
    if (this.authServer.isLogin() === true){
      const replyData = {
        Forum_Id: id,
        User_Type: 'User',
        Query: this.replyForm.value.Query
      };
      this.isloading = true;
      this.dashboardService.replyForam(replyData).subscribe(Response => {
        this.isloading = false;
        this.forum = Response.find( res => res._id === this.forumId);
        this.conversationArr = this.forum[`Conversation`];
        this.replyForm.reset();
      });

    } else{
      const dialog = this.dialog.open(LoginComponent, {
        width: '630px',
      });
      dialog.close( Response => {

      })
    }

  }
}
