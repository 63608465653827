import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CourseService } from 'src/app/service/course.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { MatSnackBar } from '@angular/material';
import { User } from 'src/app/auth/user.module';
import { ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/service/page.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userInfo: any;
  userInfo1: any;
  profileForm: FormGroup;
  fileToUpload: File = null;
  resumeToUpload: File = null;
  imageShow: any= '';
  imageUrl: string = '';
  url = '';
  
  // @ViewChild('userPhoto') userPhoto: ElementRef;
  chandePassForm = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required)
  });
  name: any;
  toggle: boolean = true;
  productID: any;
  profileForm1: FormGroup;
  constructor(private fb: FormBuilder, private rout: ActivatedRoute, private pageService: PageService, private dashServer: DashboardService, private snakbar: MatSnackBar) { }

  urls = new Array<string>();
  
  
  ngOnInit() {

   
    this.dashServer.getProfile().subscribe(Response => {
      this.userInfo = Response;
      
     
      this.profileForm.controls['Name'].setValue(this.userInfo.Name);
      this.profileForm.controls['Qualification'].setValue(this.userInfo.Qualification);
      this.profileForm.controls['Address'].setValue(this.userInfo.Address);
      this.profileForm.controls['Phone_Number'].setValue(this.userInfo.Phone_Number);
      this.imageUrl=this.userInfo.Profile_Image;
      
      this.profileForm1.controls['Name'].setValue(this.userInfo.Name);
      this.profileForm1.controls['Qualification'].setValue(this.userInfo.Qualification);
      this.profileForm1.controls['Address'].setValue(this.userInfo.Address);
      this.profileForm1.controls['Phone_Number'].setValue(this.userInfo.Phone_Number);
      this.imageUrl=this.userInfo.Profile_Image;
      
    });
    this.profileForm = this.fb.group({
      
      Name:  [{value: '', disabled : true}],
      Mobile_Number: [{value: '', disabled : true}],
      Qualification: [{value: '', disabled : true}],
      Address: [{value: '', disabled : true}],
      Phone_Number: [{value: '', disabled : true}],
      Profile_Image: [{value: '', disabled : true}],
      Image: [{value: '', disabled : true}],
      avatar: [null]
    });
    this.profileForm1 = this.fb.group({
      
      Name:  '',
      Mobile_Number: '',
      Qualification: '',
      Address: '',
      Phone_Number: '',
      Profile_Image: '',
      Image: '',
      avatar: [null]
    });
    

  }

  
  updateProfile() {
    this.toggle = !this.toggle;
    this.dashServer.getProfile().subscribe(Response => {
      this.userInfo = Response;
      
     
      this.profileForm.controls['Name'].setValue(this.userInfo.Name);
      this.profileForm.controls['Qualification'].setValue(this.userInfo.Qualification);
      this.profileForm.controls['Address'].setValue(this.userInfo.Address);
      this.profileForm.controls['Phone_Number'].setValue(this.userInfo.Phone_Number);
      this.imageUrl=this.userInfo.Profile_Image;
      this.profileForm1.controls['Name'].setValue(this.userInfo.Name);
      this.profileForm1.controls['Qualification'].setValue(this.userInfo.Qualification);
      this.profileForm1.controls['Address'].setValue(this.userInfo.Address);
      this.profileForm1.controls['Phone_Number'].setValue(this.userInfo.Phone_Number);
      this.imageUrl=this.userInfo.Profile_Image;
    });
  }
  onSubmit(Data) {
    this.toggle = true;
    
    const formData = new FormData();
    formData.append('Name', Data.Name);
    formData.append('Profile_Image', this.fileToUpload);
    formData.append('Qualification', Data.Qualification);
    formData.append('Address', Data.Address);
    formData.append('Phone_Number', Data.Phone_Number);
    
    

    this.dashServer.PostProfileData(formData).subscribe(Response => {
       this.userInfo1 = Response;
    
      
      if (Response[`success`]) {
        this.snakbar.open('Profile', Response[`msg`], {
          duration: 2000,
          panelClass: ['blue-snackbar']
        });
      } else {
        this.snakbar.open(Response[`msg`], '', {
          duration: 2000,
          panelClass: ['blue-snackbar']
        });
      }
    });

  }
  onSubmit1(Data1) {
    this.toggle = true;
    this.rout.params.subscribe((params) => {
     this.onSubmit(Data1)
    });
    const formData = new FormData();
    formData.append('Name', Data1.Name);
    formData.append('Profile_Image', this.fileToUpload);
    formData.append('Qualification', Data1.Qualification);
    formData.append('Address', Data1.Address);
    formData.append('Phone_Number', Data1.Phone_Number);
    
    

    this.dashServer.PostProfileData(formData).subscribe(Response => {
       this.userInfo1 = Response;
       this.profileForm.controls['Name'].setValue(this.userInfo1.Name);
      this.profileForm.controls['Qualification'].setValue(this.userInfo1.Qualification);
      this.profileForm.controls['Address'].setValue(this.userInfo1.Address);
      this.profileForm.controls['Phone_Number'].setValue(this.userInfo1.Phone_Number);
      this.imageUrl=this.userInfo1.Profile_Image;
    
      
      if (Response[`success`]) {
        this.snakbar.open('Profile', Response[`msg`], {
          duration: 2000,
          panelClass: ['blue-snackbar']
        });
      } else {
        this.snakbar.open(Response[`msg`], '', {
          duration: 2000,
          panelClass: ['blue-snackbar']
        });
      }
    });

  }
  handleResumeInput(files: FileList) {
    this.resumeToUpload = files.item(0);
  }
    

  
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader();
    reader.onload =(event: any) => {
      this.imageUrl= event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
   
  }

  handleResume1Input(files: FileList) {
    this.resumeToUpload = files.item(0);
  }

  uploadResume() {
    const resumeData = new FormData();
    resumeData.append('Resume', this.resumeToUpload, this.resumeToUpload.name);
    this.dashServer.uploadResume(resumeData).subscribe(Response => {
      // this.imageUrl=
      if (Response[`success`]) {
        this.snakbar.open('Resume', Response[`msg`], {
          duration: 2000,
        });
      } else {
        this.snakbar.open(Response[`msg`], '', {
          duration: 2000,
        });
      }
    });
  }

  changePassword() {
    const password = {
      oldpassword: this.chandePassForm.value.oldPassword,
      newpassword: this.chandePassForm.value.newPassword,
    };
    this.dashServer.changePassword(password).subscribe(Response => {
      if (Response[`success`]) {
        this.snakbar.open(Response[`msg`], '', {
          duration: 2000,
        });
      } else {
        this.snakbar.open(Response[`msg`], Response[`error`], {
          duration: 2000,
        });
      }
    });
  }

}


