import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map} from 'rxjs/operators';
import { throwError , BehaviorSubject} from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { formatDate } from '@angular/common';
import { Banner } from '../shared/banner';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PageService {
  cookieValue = '';
  gzip: true
  constructor(private http: HttpClient, private authService: AuthService) { }

    getForum(Course) {
      return this.http.post( environment.MICRO_SERVICE_API + 'courseforum/courseforumlist', Course).pipe(map(res => {
        if (res[`clearCookie`]) {
          this.authService.logOut();
        } else {
          return res[`response`];
        }
      }));
    }
    getAuthToken() {
      return this.cookieValue;
    }
    getCourseList() {
      return this.http.post( environment.MICRO_SERVICE_API + 'course/getallcoursedetailslist', {}).pipe(map(res => {
        if (res[`clearCookie`]) {
          this.authService.logOut();
        } else {
          return res[`response`];
        }
      }));
}
postFormData(formData) {
  return this.http.post( environment.MICRO_SERVICE_API + 'notification/generatelead', formData ).pipe(map(res => {
    return res[`response`];
  }));
}
userProfile(uid) {
  return this.http.post( environment.MICRO_SERVICE_API + 'login/userprofile ', {User_Id: uid }).pipe(map(res => {
      return res[`response`];
  }));
}
getBanner(): Observable<Banner[]> {
  return this.http.post( environment.MICRO_SERVICE_API + 'banners/getbanners', {}).pipe(map(res => {
    if (res[`clearCookie`]) {
    } else {
      return res[`response`];
    }
  }));
}
offers() {
  return this.http.post( environment.MICRO_SERVICE_API + 'offers/clientgetoffers', {})
  .pipe(map(res => {
    return res[`response`];
}));
}
applyCoupon(Data) {
  return this.http.post( environment.MICRO_SERVICE_API + 'payment/validatecoupon', Data)
    .pipe(map(res => {
      return res[`response`];
  }));
}
buyCourse(data) {
  return this.http.post( environment.MICRO_SERVICE_API + 'payment/buynow', data)
    .pipe(map(res => {
      return res[`response`];
  }));
}
addCart(userinfo) {
  return this.http.post( environment.MICRO_SERVICE_API + 'cart/addcart', userinfo ).pipe(map(res => {
    return res[`response`];
  }));
}
findCart() {
  return this.http.post( environment.MICRO_SERVICE_API + 'cart/findcartdetails', {}).pipe(map(res => {
    return res;
  }));
}
removeCart(remove) {
  return this.http.post( environment.MICRO_SERVICE_API + 'cart/deletecart', remove ).pipe(map(res => {
    return res[`response`];
  }));
}
verifyUser(verify) {
  return this.http.post( environment.MICRO_SERVICE_API + 'register/verifyuser', verify ).pipe(map(res => {
    return res;
  }));
}
resendMail(mail) {
  return this.http.post( environment.MICRO_SERVICE_API + 'register/resendverificationlink', mail ).pipe(map(res => {
    return res;
  }));
}
testimonials() {
  return this.http.post( environment.MICRO_SERVICE_API + 'testimonials/clientgettestimonials', {} ).pipe(map(res => {
    return res[`response`];
  }));
}
getResult(data) {
  return this.http.post( environment.MICRO_SERVICE_API + 'resultupload/clientgetlistdetails', data ).pipe(map(res => {
    return res[`response`];
  }));
}
  getProject() {
    return this.http.post( environment.MICRO_SERVICE_API + 'miniproject/clientgetprojectlist', {} ).pipe(map(res => {
      return res[`response`];
    }));
  }
  getProjectDetails(pid) {
    return this.http.post( environment.MICRO_SERVICE_API + 'miniproject/clientindividualgetproject', {Project_Id: pid} ).pipe(map(res => {
      return res[`response`];
    }));
  }
  Webinarlist(body) {
    return this.http.post( environment.MICRO_SERVICE_API + 'webinar/getwebinarlistclient',body).pipe(map(res => {
      return res[`response`];
    }));
  }

  Webinarregister(webinarData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'webinar/registerwebinar',webinarData).pipe(catchError(this.handleError), map(res => {
      return res[`response`];
      
    }));
  }

  WebinarDetails(webinarData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'webinar/findwebinarlistdetails',webinarData).pipe(catchError(this.handleError), map(res => {
      return res[`response`];
      
    }));
  }

  private handleError(errorRes: HttpErrorResponse) {
    if (!errorRes.error) {
     return throwError('user authentication is wrong');
   }
    return throwError('nothing');
}
}
