import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { PageService } from 'src/app/service/page.service';

interface Offer {
  Coupon_Code: string;
  Discount_Percentage: number;
  End_Date: Date;
  Maximum_Amount: number;
  Offer_Image: string;
  Offer_Name: string;
  Offer_Type: string;
  Start_Date: Date;
}
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  form = new FormGroup({
    Name: new FormControl('', Validators.required),
    Email: new FormControl('', [Validators.required, Validators.email]),
    PhoneNo: new FormControl('', Validators.required)

  });

  constructor(private pageService: PageService) { }
  offer: Offer;
  coupan: 'NA';
  getOffer: boolean;
  error: {};
  ngOnInit() {
      this.pageService.offers().subscribe((Response: Offer) => {
        this.offer = Response;
        this.getOffer = true;
      });
  }

  submitOffer() {
    const formData = {
      Name: this.form.value.Name,
      Phone_Number: this.form.value.PhoneNo,
      Email: this.form.value.Email,
      User_Type: 'User',
      Coupon_Code: this.coupan
    };
    this.pageService.postFormData(formData).subscribe( Response => {
      this.getOffer = false;
    }, error => this.error = error);
  }
  getCoupan(coupan) {
    this.coupan = coupan;
  }
}
