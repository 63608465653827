import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { CoursedialogComponent } from '../coursedialog/coursedialog.component';
import { CourseService } from 'src/app/service/course.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { EnrollCourse } from 'src/app/course/enroll';
import { StarRatingComponent } from 'ng-starrating';
import { Course, Price } from '../../course/course';



@Component({
  selector: 'app-enrollcourse',
  templateUrl: './enrollcourse.component.html',
  styleUrls: ['./enrollcourse.component.css']
})

export class EnrollcourseComponent implements OnInit {
  enrollCourseList: EnrollCourse[];
  relatedCourse: any[];
  CourseId: string;
  course: Course;
  Average_Rating: string = "";
  Total_Reviewers: number = 0;
  Five_Star: number = 0;
  Four_Star: number = 0;
  Three_Star: number = 0;
  Two_Star: number = 0;
  One_Star: number = 0;
  constructor( public dialog: MatDialog, private rout: ActivatedRoute, private courseService: CourseService, private dashboardService: DashboardService) { }
  openInstruction(courseId): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        Course:  this.enrollCourseList.find(resp =>  resp._id === courseId ) ,
    };
    dialogConfig.width = "80%";
    // dialogConfig.height = "400px";
    const dialogRef = this.dialog.open(CoursedialogComponent, dialogConfig );
    
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  ngOnInit() {
    
    this.dashboardService.enrollCourse().subscribe(Response => {
      this.enrollCourseList = Response;
      
      // this.courseUrl = Response[0].CourseDetails.Course_URL;
    });




    // this.courseService.reletedCourse('python-programming-certification-course').subscribe(Response => {
    //   this.relatedCourse = Response;
    // });

  }

}
