import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'prwatech';
  showNavigation = true; 
  
  constructor( public breakpointObserver: BreakpointObserver, private router: Router, private authUser: AuthService, private spinner: NgxSpinnerService) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
  ngOnInit() {

    // Listen for navigation events to show/hide navigation
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is "ai-tutor"
        if (event.urlAfterRedirects.includes('ai-tutor')) {
          this.showNavigation = false; // Hide navigation on this page
        } else {
          this.showNavigation = true; // Show navigation on other pages
        }
      }
    });


    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        'Breakpoints.XSmall: max-width = 599.99px'
      }
      if (result.breakpoints[Breakpoints.Small]) {
       // handle Small breakpoint
       'Breakpoints.Small: min-width = 600px and max-width = 959.99px'
      }
      if (result.breakpoints[Breakpoints.Medium]) {
      // handle Medium breakpoint
      'Breakpoints.Medium: min-width = 960px and max-width = 1279.99px'
      }
      if (result.breakpoints[Breakpoints.Large]) {
        // handle Large breakpoint
        'Breakpoints.Large: min-width = 1280px and max-width = 1919.99px'
      }
      if (result.breakpoints[Breakpoints.XLarge]) {
        // handle XLarge breakpoint
        'Breakpoints.XLarge: min-width = 1920px'
      }
    });
    this.authUser.autologin();
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         
        } else {
          
        }
      });
  }
  
}
