import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { EnrollcourseComponent } from './enrollcourse/enrollcourse.component';
import { OrderComponent } from './order/order.component';
import { WalletComponent } from './wallet/wallet.component';
import { AuthGuard } from '../auth/auth.guard';
import { StudentDashboardComponent } from './studentdashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JobappliedComponent } from './jobapplied/jobapplied.component';
import { MiniprojectComponent } from './miniproject/miniproject.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { MiniprojectDetailsComponent } from './miniproject-details/miniproject-details.component';
import { SupportComponent } from './support/support.component';
import { ForumComponent } from './forum/forum.component';
import { VideoComponent } from '../pages/video/video.component';

const routes: Routes = [
  { path: 'dashboard',
    component: StudentDashboardComponent,
    // canActivate: [AuthGuard],
    children: [
            {path: '', component: DashboardComponent },
            {path: 'profile', component: ProfileComponent},
            {path: 'enrollcourse', component: EnrollcourseComponent},
            {path: 'order', component: OrderComponent},
            {path: 'wallet', component: WalletComponent},
            {path: 'jobapplied', component: JobappliedComponent},
            {path: 'project', component: MiniprojectComponent},
            {path: 'projectvideo/:pid', component: MiniprojectDetailsComponent},
            {path: 'activitylog', component: ActivityLogComponent},
            {path: 'student-forum', component: ForumComponent},
            {path: 'support', component: SupportComponent}

          ] },
    {path: 'video',  canActivate: [AuthGuard], component: VideoComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentdashboardRoutingModule { }
