import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { StarRatingComponent } from 'ng-starrating';



@Component({
  selector: 'app-onlinecourse',
  templateUrl: './onlinecourse.component.html',
  styleUrls: ['./onlinecourse.component.css']
})
export class OnlinecourseComponent implements OnInit {
  selfPaced: any[];
  Colors: any[] = [
    'radial-gradient(circle at 94% 49%,#03f5a7,#1091ff)',
    'radial-gradient(circle at 27% 34%,#b40285,#e506ca)',
    'radial-gradient(circle at 94% 49%,#c600f7,#1a00a7)',
    'radial-gradient(circle at 94% 49%,#f58978,#d9288a)',
    'radial-gradient(circle at 94% 49%, rgb(217, 150, 0), rgb(233, 196, 14))',
    'radial-gradient(circle at 94% 49%, rgb(208, 3, 245), rgb(119, 16, 255))',
    'radial-gradient(circle at 94% 49%, rgb(88, 169, 117), rgb(64, 210, 130))'
  ];
  randamColor: any[];
  checkedcolor: "gold";
  uncheckedcolor: "gray";
  size: "24px";
  value: 0;
  readonly: true;
  Average_Rating: number = 0;
  fruits = [{
    id: 1,
    name: 'Apple'
  },{
    id: 2,
    name: 'Mango'
  }]
  constructor(private courseServer: CourseService) {
    
   }

  ngOnInit() {
    this.courseServer.selfPaced().subscribe(Response => {
      this.selfPaced = Response;
      const Count = Math.ceil(this.selfPaced.length / this.Colors.length);
      this.randamColor = []
      for (var i = 0; i < Count + 1; i++) {
        this.randamColor.push(...this.Colors)
      }

        this.selfPaced.forEach((element, index) => {

          let Reviews = element['CourseReview']
          let Total_Review = 0;
          Reviews.forEach(element => { Total_Review += element['Review_Number'] });
          this.selfPaced[index]['Total_Reviewers'] = Reviews.length
          this.selfPaced[index]['Average_Rating'] = Total_Review > 0 && Reviews.length > 0 ? (Total_Review / Reviews.length).toFixed(1) : 0;
        })

        this.selfPaced.forEach((element,index) => {
            
            let Reviews = element['CourseReview']
            let Total_Review = 0 ;
            Reviews.forEach(element => { Total_Review += element['Review_Number'] });
            this.selfPaced[index]['Total_Reviewers'] = Reviews.length
            this.selfPaced[index]['Average_Rating'] =  Total_Review > 0 &&  Reviews.length > 0 ? (Total_Review /  Reviews.length).toFixed(1) : 0;
        })
           
    });
    
  }
  onRate($event:{oldValue:number, newValue:number, starRating:StarRatingComponent}) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }
}
