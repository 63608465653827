import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/service/page.service';
import { MatDialog } from '@angular/material';
import { Banner } from 'src/app/shared/banner';
import { OfferComponent } from 'src/app/shared/offer/offer.component';
import { CourseService } from 'src/app/service/course.service';
import { Title, Meta } from '@angular/platform-browser';
import { Seo } from 'src/app/course/courseseo';

import { WebinarformComponent } from 'src/app/webinarform/webinarform.component';
// import{ WebinardetailComponent } from 'src/app/webinardetail/webinardetail.component'
import { Router } from '@angular/router';
import { TransfereService } from 'src/app/service/data-transfer'
@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent implements OnInit {
  Current_Date: any;
  webinar: any[];
  isAuthenticate: boolean;

  constructor(private courseServer: CourseService, private pageService: PageService,
    public dialog: MatDialog, private title: Title, private meta: Meta, private router: Router, private transferService: TransfereService) { }
  openwebinardetail(data) {
    this.router.navigateByUrl('pages/webinardetail/' + data._id);
  }
  openOfferDialog(offer): void {
    const dialogRef = this.dialog.open(OfferComponent, {
      width: '800px',
      data: {
        offerClick: offer,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openwebinarform(Webinar): void {
    if (Webinar.Webinar_Type === 'Webinar_Form') {
      const dialogRef = this.dialog.open(WebinarformComponent, { width: '620px' });
      this.transferService.setData(Webinar);
      dialogRef.afterClosed().subscribe(result => {
        if (typeof (result) === 'undefined') {
        } else {
          this.isAuthenticate = result.data;
        }
      });
    } else {
      window.open(Webinar.Webinar_Link, "_blank");
    }

  }

  ngOnInit() {
    this.Current_Date = new Date().toISOString();
    this.pageService.Webinarlist({}).subscribe(Response => {
      this.webinar = Response;
      this.webinar.sort((a,b) => b.Webinar_Date_Time.localeCompare(a.Webinar_Date_Time));

    });
  }

}
