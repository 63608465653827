import { Component, OnInit , Inject} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import { CourseService } from 'src/app/service/course.service';
import { AuthService } from 'src/app/service/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { Router } from '@angular/router';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-batchesdialog',
  templateUrl: './batchesdialog.component.html',
  styleUrls: ['./batchesdialog.component.css']
})
export class BatchesdialogComponent implements OnInit {
  batchList: any[];
  courseName: string;
  courseURL: string;
  courseType: any[];
  courseid: any[];
  constructor(private dialogRef: MatDialogRef<BatchesdialogComponent>, @Inject(MAT_DIALOG_DATA) data: any,
              private courseService: CourseService, private pageService: PageService,
              public dialog: MatDialog, private authService: AuthService,
              private router: Router) {
    this.batchList = data.Schedules;
    this.courseName = data.courseName;
    this.courseURL = data.courseURL;
    this.courseType = data.courseTypes;
    this.courseid = data.courseId;
   }

  ngOnInit() {

  }

  // tslint:disable-next-line: use-life-cycle-interface
  // ngOnDestroy() {
  //   window.scrollTo(0, 0);
  // }
  onEnroll(CourseTypes, courseId, Schedules) {
    const enrollDate = {
      Course_Id: courseId,
      Course_Type: CourseTypes,
      Purchase_Type : 'Course',
      Schedule_Id: Schedules
    };
    if (this.authService.isLogin() === true) {
      this.pageService.addCart(enrollDate).subscribe( Response => {
        this.router.navigate(['pages/cart']);
      });
    } else {
      this.dialog.open(LoginComponent, {
        width: '620px'
      });
    }
  }
}
