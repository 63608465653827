import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import { EnrollCourse } from 'src/app/course/enroll';

@Component({
  selector: 'app-coursedialog',
  templateUrl: './coursedialog.component.html',
  styleUrls: ['./coursedialog.component.css']
})
export class CoursedialogComponent implements OnInit {
  courselist: EnrollCourse;

  constructor(private router: Router , private dialogRef: MatDialogRef<CoursedialogComponent>,
    // tslint:disable-next-line: align
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.courselist = data.Course;
    }

   viewMode = 'instruction';
   onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/video'], { queryParams: { id: this.courselist._id } });
  }
   ngOnInit() {

  }

}
