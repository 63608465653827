import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrainerService {
  coursecategory: any[];
  constructor(private http: HttpClient) { }

  getTrainerList() {
    return this.http.post(environment.MICRO_SERVICE_API + 'trainer/clientgettrainerslist',{}).pipe(map(res => {
      return res[`response`];
    }));
  }

  getTrainerDetails(id) {
    return this.http.post( environment.MICRO_SERVICE_API + 'trainer/clientgettrainercourselist', { Trainer_Id: id}).pipe(map(res => {
      return res;
    }));
  }
  trainerCourseDetails(CourseId) {
    return this.http.post( environment.MICRO_SERVICE_API + 'trainer/clientgettrainercoursedetails', { Course_Id: CourseId})
    .pipe(map(res => {
      return res[`response`];
    }));
  }
  trainerSignUp(formdata) {
    return this.http.post( environment.MICRO_SERVICE_API + 'register/trainer', formdata ).pipe(map(res => {
      return res;
    }));
  }

}

