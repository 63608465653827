import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobService } from 'src/app/service/job.service';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-jobform',
  templateUrl: './jobform.component.html',
  styleUrls: ['./jobform.component.css']
})
export class JobformComponent implements OnInit {
  fileToUpload: File = null;
  jobId: string;
  resume: string;
  constructor( public dialogRef: MatDialogRef<JobformComponent>, private jobServer: JobService,
               @Inject(MAT_DIALOG_DATA) data: any, private snackBar: MatSnackBar ) {
    this.jobId = data.id;
    this.resume = data.resume;
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
}
  ngOnInit() {
  }

  applyJob(jobId) {
    const fd = new FormData();
    fd.append('Job_Id', jobId);
    this.fileToUpload && this.fileToUpload.name ? fd.append('Resume', this.fileToUpload, this.fileToUpload.name) : fd.append('Resume', '' );
    this.jobServer.PostResume(fd).subscribe(Response => {
      this.snackBar.open( 'Job Applied', 'job', {
        duration: 2000,
      });
      this.dialogRef.close();
      location.reload();
    });

  }

}
