import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-miniproject',
  templateUrl: './miniproject.component.html',
  styleUrls: ['./miniproject.component.css']
})
export class MiniprojectComponent implements OnInit {
  miniProject: any[];
  constructor(private dashbordService: DashboardService) { }

  ngOnInit() {
    this.dashbordService.getMiniProject().subscribe( Response => {
      this.miniProject = Response;
    });
  }

}
