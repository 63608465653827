import { Component, OnInit } from '@angular/core';
import { JobService } from 'src/app/service/job.service';
import { Seo } from 'src/app/course/courseseo';
import { CourseService } from 'src/app/service/course.service';
import { Title, Meta } from '@angular/platform-browser';
import { Jobs } from '../jobs';

@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css']
})
export class JoblistComponent implements OnInit {
  jobList: Jobs[];
  pageSeo: Seo;
  constructor(private jobs: JobService, private courseServer: CourseService, private title: Title, private meta: Meta ) { }

  ngOnInit() {
    this.jobs.getJobsList().subscribe(Response => {
      this.jobList = Response;
    });
  }

}
