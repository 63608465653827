import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/service/course.service';
import { FormGroup, FormControl } from '@angular/forms';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  verify: boolean;
  sendMail: boolean;
  email: boolean;
  emailForm = new FormGroup({
    Email: new FormControl('')
  });
  constructor( private dialog: MatDialog, private rout: ActivatedRoute, private pageService: PageService) { }
  ngOnInit() {
    this.rout.queryParams.subscribe( params => {
      this.pageService.verifyUser(params).subscribe( Response => {
        this.verify = Response[`success`];
        this.email  = Response[`success`];
      });
    });
  }
  onLogin() {
    this.dialog.open(LoginComponent, {
      width: '620px',
  });
}
resendEmail() {
  const email = {
    Email: this.emailForm.value.Email
  };
  this.pageService.resendMail(email).subscribe( Response => {
    this.sendMail = Response[`success`];
    this.email = true;
  });
  this.emailForm.reset();
}
}
