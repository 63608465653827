// import { library } from '@fortawesome/fontawesome-svg-core';

// import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
// import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
// import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
// // import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
// // import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
// // import { faTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
// // import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
// // import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
// // import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
// // import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
// // import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
// // import { faMix } from '@fortawesome/free-brands-svg-icons/faMix';
// // import { faXing } from '@fortawesome/free-brands-svg-icons/faXing';
// // import { faLine } from '@fortawesome/free-brands-svg-icons/faLine';

// // import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
// // import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
// // import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
// // import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
// // import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
// // import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
// // import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
// // import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';


// const icons = [
//   faFacebookF, faTwitter, faLinkedinIn
// ];


// library.add(...icons);
