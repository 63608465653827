import { Injectable } from '@angular/core';

import { DialogBoxComponent } from './dialog-box.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class DialogBoxService {

  constructor(
    private modalService: NgbModal
  ) { }
  public confirm(
    Course_Feature : any,
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(DialogBoxComponent, { size: dialogSize });
    modalRef.componentInstance.Course_Feature = Course_Feature;

    return modalRef.result;
  }
}
