import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ForumDialogComponent } from '../forum-dialog/forum-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {
  foramData: any[];
  replyForm = new FormGroup({
    Query: new FormControl()
  });
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) { }

  ngOnInit() {
    this.dashboardService.getForam().subscribe( Response => {
      this.foramData = Response;
    });
  }
  onReply(ForumId) {
    const replyData = {
      Forum_Id: ForumId,
      User_Type: 'User',
      Query: this.replyForm.value.Query
    };
    this.dashboardService.replyForam(replyData).subscribe(Response => {
      this.replyForm.reset();
      this.foramData = Response;
    });

  }
  openForum(forumId) {
    this.dialog.open(ForumDialogComponent, {
      data: {
        fid: forumId
      }
    });
  }
}
