import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import {MatDialogModule} from '@angular/material/dialog';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../shared/angular-material/angular-material.module';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoadingSpinnerComponent } from '../shared/loading.component';



@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AngularMaterialModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    LoginComponent,
    ForgotPasswordComponent,
    LoadingSpinnerComponent
  ],

})
export class AuthModule { }
