import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../shared/angular-material/angular-material.module';
import { JobsComponent } from './jobs.component';
import { JobdetailsComponent } from './jobdetails/jobdetails.component';
import { JobformComponent } from './jobform/jobform.component';
import { JoblistComponent } from './joblist/joblist.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterModule } from 'src/app/layout/footer/footer.module';

@NgModule({
    declarations: [
        JobsComponent,
        JobdetailsComponent,
        JobformComponent,
        JoblistComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        AngularMaterialModule,
        NgxSpinnerModule,
        FooterModule
    ],
    exports: [JobsComponent,
        JobdetailsComponent,
        JobformComponent,
        JoblistComponent]
})
export class JobModule { }
