import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/service/page.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Forum } from 'src/app/studentdashboard/forum-dialog/forum-dialog.component';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {
  forumData: any[];
  courseList: any[];

  conversationArr: any[];
  constructor( private pageService: PageService, private dashboardService: DashboardService) { }
  ngOnInit() {
    this.pageService.getCourseList().subscribe( Response => {
      this.courseList = Response;
    });
    this.pageService.getForum({}).subscribe( Response => {
      this.forumData = Response;
    });
  }

  selectCategory(Category) {
      const category = Category.value;
      this.pageService.getForum({'Course_Id': category}).subscribe(Response => {
      this.forumData = Response;
      });

  }


}
