import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  refferCode: string;
  Message: string;
  reffrelForm = new FormGroup({
    email: new FormControl('', [
       Validators.required,
       Validators.email
    ])
  });
  constructor( private dashBoardService: DashboardService, private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.dashBoardService.getProfile().subscribe( Response => {
      this.refferCode = Response.Referal_Code;
    });
  }

  onReffrel(): void {
    const data = {
      Email: this.reffrelForm.value.email
    };
    this.dashBoardService.reffrelCode(data).subscribe( Response => {
      this.Message = Response[`Msg`];
      this.reffrelForm.reset();
    });

  }

}
