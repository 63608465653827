import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/course/course';
import { Title } from '@angular/platform-browser';
import { PageService } from 'src/app/service/page.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  cartList: Course;
  totalAmount: number;
  emptyCart: boolean;
  CartLength : 0;
  // price: any[];
  constructor(private pageServices: PageService, private title: Title ,private cookieService: CookieService) { }

  ngOnInit() {
    this.title.setTitle('About');
    this.pageServices.findCart().subscribe( Response => {
      this.cartList = Response[`response`];
      this.CartLength = Response[`response`].length;
      this.emptyCart = Response[`Status`];
    });
  }
  removeCart(sid, cid, ctype, ptype) {
    const removeData = {
      Course_Id: cid,
      Course_Type: ctype,
      Purchase_Type : ptype,
      Schedule_Id: sid
    };
    this.pageServices.removeCart(removeData).subscribe(Response => {
      this.pageServices.findCart().subscribe( res => {
        this.cartList = res[`response`];
        this.CartLength = res[`response`].length;
    });
  });
}
removeprojectCart(pid) {
  const removeData = {
    Project_Id: pid,
    Purchase_Type : 'Project',
  };
  this.pageServices.removeCart(removeData).subscribe(Response => {
    this.pageServices.findCart().subscribe( res => {
      this.cartList = res[`response`];
      this.CartLength = res[`response`].length;
  });
});
}

  checkoutToPayments(){
    window.open(`https://payments.eduprwa.com/paynow/${localStorage.getItem('auth')}`,'_self');
  }
}
