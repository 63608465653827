import { Component, OnInit } from '@angular/core';
import { TrainerService } from 'src/app/service/trainer.service';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.css']
})
export class TrainerComponent implements OnInit {
  trainerList: any[];
  constructor( private trainerService: TrainerService) { }
  ngOnInit() {
    this.trainerService.getTrainerList().subscribe(Response => {
      this.trainerList = Response;
    });
  }

}
