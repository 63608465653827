import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { WebinarformComponent } from '../webinarform/webinarform.component';
import { PageService } from 'src/app/service/page.service';
import { TransfereService } from '../service/data-transfer';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';




interface WebinarDetail {
  Webinar_Title: string,
  Webinar_Description: any,
  Webinar_Date_Time: string
}

@Component({
  selector: 'app-webinardetail',
  templateUrl: './webinardetail.component.html',
  styleUrls: ['./webinardetail.component.css'],
  providers: [DatePipe]
})
export class WebinardetailComponent implements OnInit {
  dataValue: WebinarDetail;
  isAuthenticate: boolean;
  webinar: any[];
  product: any[];
  today;
  Webinar_Date_Time: Date;
  webinar1: any[];
 
  webinarlist: any[];

  constructor(private sanitizer: DomSanitizer,private datePipe: DatePipe, private rout: ActivatedRoute, private router: Router, private pageService: PageService, public dialog: MatDialog, private transferservice: TransfereService) {
    
   }

  ngOnInit() {

    this.rout.params.subscribe(params => {

    });
    this.today = new Date()

    this.pageService.Webinarlist({}).subscribe(Response => {
      this.webinar1 = Response;
    });
    this.pageService.Webinarlist({ "Current_Date": new Date() }).subscribe(Response => {
      this.webinar = Response;
    });

    this.rout.params.subscribe((params: Params) => {
      this.pageService.WebinarDetails({ 'Webinar_Id': params.id }).subscribe(Response => {
        this.dataValue = Response;
        this.dataValue.Webinar_Description = this.sanitizer.bypassSecurityTrustHtml(this.dataValue.Webinar_Description);
      });
    })
  }
  Ctrl($scope) {
    $scope.date = new Date();
  }

  ValidateTime(Time){
    return new Date(Time) < new Date()
    
  }

  openwebinarform(link): void {



    if (link.Webinar_Type === 'Webinar_Form') {

      const dialogRef = this.dialog.open(WebinarformComponent, { width: '620px' });
      this.transferservice.setData(link);
      dialogRef.afterClosed().subscribe(result => { typeof (result) === 'undefined' ? {} : this.isAuthenticate = result.data; });
    } else {

      window.open(link.Webinar_Link, "_blank");
    }
  }

}
