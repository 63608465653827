import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Course } from '../../course/course';
import { Title } from '@angular/platform-browser';
import { PageService } from 'src/app/service/page.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  courseUrl: string;
  course: Course;
  Course_Id: string;
  CourseTypes: string;
  CourseTiming: Date;
  amount: number;
  userAmount: number;
  couponResponse: any[];
  Coupon: string;
  data: any;
  schedule_Id: string;
  paymentList: any[];
  Total_Amount = 0;
  Discount = 0;
  constructor(private rout: ActivatedRoute, private pageService: PageService, private router: Router, private title: Title) { }
  couponSubmit(f) {
     this.Coupon = f.value.coupon;
     const data = {
      Coupon_Code  : this.Coupon,
      User_Amount  : this.Total_Amount - this.Discount,
      Courses      : []
    };
     const Promise_Array = [];
     this.paymentList.forEach(x => Promise_Array.push(this.FilterCoupon(x))) ;
     Promise.all(Promise_Array)
          .then(response => {
                data.Courses = response;
                this.pageService.applyCoupon(data).subscribe( Response => {

                  this.Discount = Response.Discount;
                });
          })
          .catch(error => {

          });


  }
  onPayment() {
    const data = {
      Coupon_Code  : this.Coupon,
      User_Amount  : 20000,
      Courses      : []
    };
    const Promise_Array = [];
    this.paymentList.forEach(x => Promise_Array.push(this.FilterData(x))) ;
    Promise.all(Promise_Array)
          .then(response => {

                data.Courses = response;
                this.pageService.buyCourse(data).subscribe(Response => {
                    // this.router.navigate(['dashboard/enrollcourse']);

                });
          })
          .catch(error => {

          });

  }

  FilterData(Course_Data) {

      return new Promise((resolve, reject) => {
            const Data = {};
            if ( Course_Data.Purchase_Type === 'Course' ) {
                  const Pricing = Course_Data[`Pricing`].find(x => x[`Course_Type`] === Course_Data[`Course_Type`]);
                  Data[`Course_Id`]     = Course_Data[`_id`];
                  Data[`Course_Type`]   = Course_Data[`Course_Type`];
                  Data[`Amount`]        = Pricing && Pricing[`Discounted_Price`] ? Pricing[`Discounted_Price`] : ` `;
                  Data[`Purchase_Type`] = Course_Data[`Purchase_Type`];
                  Course_Data[`Schedule_Id`] ? Data[`Schedule_Id`] = Course_Data[`Schedule_Id`] : `` ;
                  resolve(Data);
            } else if (Course_Data.Purchase_Type === `Project`) {
                  Data[`Project_Id`]     = Course_Data[`_id`];
                  Data[`Amount`]         = Course_Data[`Discounted_Price`]
                  Data[`Purchase_Type`]  = Course_Data[`Purchase_Type`];
                  resolve(Data);
            } else {

                resolve();
            }
      });
  }
  FilterCoupon(Course_Data) {
    return new Promise((resolve, reject) => {
          const Data = {};
          if ( Course_Data.Purchase_Type === 'Course' ) {
                const Pricing = Course_Data[`Pricing`].find(x => x[`Course_Type`] === Course_Data[`Course_Type`]);
                Data[`Course_Id`]     = Course_Data[`_id`];
                Data[`Course_Type`]   = Course_Data[`Course_Type`];
                Data[`Amount`]        = Pricing && Pricing[`Discounted_Price`] ? Pricing[`Discounted_Price`] : ` `;
                Data[`Purchase_Type`] = Course_Data[`Purchase_Type`];
                resolve(Data);
          } else if (Course_Data.Purchase_Type === `Project`) {
                Data[`Project_Id`]     = Course_Data[`_id`];
                Data[`Amount`]         = Course_Data[`Discounted_Price`]
                Data[`Purchase_Type`]  = Course_Data[`Purchase_Type`];
                resolve(Data);
          } else {

              resolve();
          }
    });
}

  ngOnInit() {
    this.title.setTitle('Payment');
    this.pageService.findCart().subscribe( Response => {
      this.paymentList = Response[`response`];

      const Promise_Array = [];
      this.paymentList.forEach(x => Promise_Array.push(this.FilterData(x))) ;
      Promise.all(Promise_Array)
            .then(response => {

                const Amounts = response.map(x => x[`Amount`]);
                this.Total_Amount = Amounts.reduce(( a , b ) => a + b , 0 );

            })
            .catch(error => {

            });
    });
}

  alertResponse(res){
    console.log(res);
  }

  handleResponse(res) {
    alert('Response received');
    console.log('Response in pipe separated string to verify the transaction status' + res.stringResponse);
    console.log('Complete response:');
    console.log(res);
    this.alertResponse(res)
  }

  proccedNow() {
    let returnUrl = 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp';
    let configJson = {
      'tarCall': false,
      'features': {
        'showPGResponseMsg': false,
        'enableExpressPay': true,
        'enableNewWindowFlow': true    //for hybrid applications please disable this by passing false
      },
      'consumerData': {
        'deviceId': 'WEBSH2',	//possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
        'token': 'D8364F11D14C8B7920733F3E2079FA63F6FA31CE6F7EC37C3493E34D10AD3EB8CA652CC683DB04F98881F41D7F0E96CED0ABE1DC1612B6B211A1480DCAA554F6',
        //'returnUrl': returnUrl,
        'responseHandler': this.handleResponse,
        'paymentMode': 'all',
        'merchantId': 'T301890',
        'currency': 'INR',
        'consumerId': 'c964634',
        'consumerMobileNo': '9876543210',
        'consumerEmailId': 'test@test.com',
        'txnId': '2481197581116',   //Unique merchant transaction ID
        'items': [{
          'itemId': 'first',
          'amount': '2',
          'comAmt': '0'
        }],
        'customStyle': {
          'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
          'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
          'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
          'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
        }
      }
    };
    /*if (returnUrl) {
      configJson.consumerData["returnUrl"] = returnUrl;
    }*/
    var checkoutLoop = setInterval(function () {
      if (typeof $["pnCheckout"] != 'undefined') {
        $["pnCheckout"](configJson);
        if (configJson.features.enableNewWindowFlow) {
          window["pnCheckoutShared"].openNewWindow();
        }
        clearInterval(checkoutLoop);
      }
    }, 500);
  }
}








// this.rout.queryParams.subscribe((params: Params) => {
    //   this.CourseTypes = params.courseType;
    //   this.CourseTiming = params.date;
    //   this.schedule_Id = params.id;
    //   console.log( this.CourseTypes );
    // }
    //   );
  //   this.rout.params.subscribe(
  //     (prams: Params) => {
  //       this.courseUrl = prams[`courseUrl`];
  //       this.pageService.getCourseDetails(this.courseUrl).subscribe((Response: Course) => {
  //         this.course = Response;
  //         this.Course_Id = Response._id;
  //         this.amount = Response.Pricing.find( res =>  res.Course_Type === this.CourseTypes);

  //     }
  //   );
  // }
  //   );
