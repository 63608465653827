import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {

  constructor() { }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
