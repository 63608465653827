import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { CourseService } from 'src/app/service/course.service';
import { EnrollCourse } from 'src/app/course/enroll';
import { DashboardInfo } from '../dashboadrinfo';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CoursedialogComponent } from '../coursedialog/coursedialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  popularCourse: any[];
  enrollCourseList: EnrollCourse[];
  dashboardInfo: DashboardInfo;
  constructor( private courseService: CourseService, private dashbordService: DashboardService, public dialog: MatDialog) {}

   ngOnInit() {
    this.courseService.clientgethomecourse().subscribe( Response => {
      this.popularCourse  = Response;
    });
    this.dashbordService.enrollCourse().subscribe(Response => {
      this.enrollCourseList = Response;
    });
    this.dashbordService.dashboard().subscribe( Response => {
      this.dashboardInfo = Response;
    });

  }

  openInstruction(courseId): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        Course:  this.enrollCourseList.find(resp =>  resp._id === courseId ) ,
    };
    const dialogRef = this.dialog.open(CoursedialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
