import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingModule } from 'ng-starrating';
import { StudentdashboardRoutingModule } from './studentdashboard-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { EnrollcourseComponent } from './enrollcourse/enrollcourse.component';
import { OrderComponent } from './order/order.component';
import { WalletComponent } from './wallet/wallet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentDashboardComponent } from './studentdashboard.component';
import { CoursedialogComponent } from './coursedialog/coursedialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../shared/angular-material/angular-material.module';
import { JobappliedComponent } from './jobapplied/jobapplied.component';
import { PagesModule } from '../pages/pages.module';
import { MiniprojectComponent } from './miniproject/miniproject.component';
import { MiniprojectDetailsComponent } from './miniproject-details/miniproject-details.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ForumDialogComponent } from './forum-dialog/forum-dialog.component';
import { SupportComponent } from './support/support.component';
import { AuthService } from '../service/auth.service';
import { AuthModule } from '../auth/auth.module';
import { LoaderComponent } from './loader/loader.component';
import { ForumComponent } from './forum/forum.component';


@NgModule({
  declarations: [
      CoursedialogComponent,
      DashboardComponent,
      StudentDashboardComponent,
      ProfileComponent,
      EnrollcourseComponent,
      OrderComponent,
      WalletComponent,
      JobappliedComponent,
      MiniprojectComponent,
      MiniprojectDetailsComponent,
      ActivityLogComponent,
      ForumComponent,
      ForumDialogComponent,
      SupportComponent,
      LoaderComponent,


    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    PagesModule,
    AuthModule,
    RatingModule,
    StudentdashboardRoutingModule
  ],
  exports: [
    ProfileComponent,
    EnrollcourseComponent,
    OrderComponent,
    WalletComponent,
    StudentDashboardComponent,
    DashboardComponent,
    CoursedialogComponent,
    LoaderComponent
  ],
  entryComponents: [CoursedialogComponent],
})
export class StudentdashboardModule { }
