import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-studentDashboard',
  templateUrl: './studentdashboard.component.html',
  styleUrls: ['./studentdashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
  asideDisplay: boolean;
  constructor( private authService: AuthService) {}
   ngOnInit() {
     this.asideDisplay = false;
  }
  onLogout() {
    this.authService.logOut().subscribe( Response => {
    });
  }
  toggle() {
    this.asideDisplay = !this.asideDisplay;
  }


}
