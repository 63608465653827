import { Component, OnInit } from '@angular/core';
import { Banner } from 'src/app/shared/banner';
import { MatDialog } from '@angular/material';
import { OfferComponent } from 'src/app/shared/offer/offer.component';
import { CourseService } from 'src/app/service/course.service';
import { Title, Meta } from '@angular/platform-browser';
import { Seo } from 'src/app/course/courseseo';
import { PageService } from 'src/app/service/page.service';
import { WebinarformComponent } from 'src/app/webinarform/webinarform.component';
// import{ WebinardetailComponent } from 'src/app/webinardetail/webinardetail.component'
import { Router } from '@angular/router';
import { TransfereService } from 'src/app/service/data-transfer'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  testimonials: any[];
  featurebgColor: any[] = [
    'linear-gradient(to right, rgba(13, 0, 99, 0.973), rgba(2, 130, 192, 0.897),rgba(0, 172, 180, 0.56),rgb(65, 122, 125))',
    'linear-gradient(to right, rgb(30, 112, 112), rgba(0, 124, 171, 0.85), rgba(145, 118, 255, 0.78), rgba(153, 80, 208, 0.65))',
    'linear-gradient(to right, rgb(9, 0, 58), rgba(59, 62, 166, 0.91),rgba(239, 126, 247, 0.37),rgba(238, 174, 99, 0.5))',
    'linear-gradient(to right, rgb(43, 22, 17), rgba(100, 82, 0, 0.65),rgba(143, 225, 124, 0.2),rgba(167, 244, 223, 0.5))',
  ];
  webinarboo: boolean;
  jobList: any[];
  webinar: any[];
  popularCourse: any[];
  selfPaced: any[];
  bannerArr: Banner[];
  position1: any;
  position2: any;
  position3: any;
  position4: any;
  position5: any;
  ptitle1: string;
  ptitle2: string;
  ptitle3: string;
  ptitle4: string;
  ptitle5: string;
  pageSeo: Seo;
  isAuthenticate: boolean;
  keepGoing:boolean;
  randamIndex: number = Math.round(Math.random() * 4);
  Array:any[];

  constructor(private courseServer: CourseService, private pageService: PageService,
    public dialog: MatDialog, private title: Title, private meta: Meta, private router: Router, private transferService: TransfereService
  ) {
    // this.webinar = Array(4).fill(3).map((x,i)=>i); 
    // this.webinar = Array(4).fill(3);
  }

  openwebinardetail(data) {
    this.router.navigateByUrl('pages/webinardetail/' + data._id);
   
    // this.router.navigate(data);

  }
  routeToBlogPage() {
    window.open(
      'https://prwatech.in/blog/',
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  openOfferDialog(offer): void {
    const dialogRef = this.dialog.open(OfferComponent, {
      width: '800px',
      data: {
        offerClick: offer,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openwebinarform(Webinar): void {
    if (Webinar.Webinar_Type === 'Webinar_Form') {
      const dialogRef = this.dialog.open(WebinarformComponent, { width: '620px' });
      this.transferService.setData(Webinar);
      dialogRef.afterClosed().subscribe(result => {
        if (typeof (result) === 'undefined') {
        } else {
          this.isAuthenticate = result.data;
        }
      });
    } else {
      window.open(Webinar.Webinar_Link, "_blank");
    }

  }
  bannerurl(bannerlink){
    if(bannerlink.Banner_URL===undefined){
      this.router.navigateByUrl('/error/');
    }
    else{
      window.open(bannerlink.Banner_URL);
    }
  }


  ngOnInit() {
    this.title.setTitle('Home');
    this.courseServer.getPageSeo('Home').subscribe(Response => {
      this.pageSeo = Response;
      if (typeof (this.pageSeo) !== 'undefined') {
        this.meta.updateTag({ name: 'description', content: this.pageSeo.Meta_Description });
        this.meta.updateTag({ name: 'keywords', content: this.pageSeo.Meta_Keywords });
        this.meta.updateTag({ name: 'abstract', content: this.pageSeo.Meta_Abstract });
        this.meta.updateTag({ name: 'Author', content: this.pageSeo.Meta_Author });
        this.meta.updateTag({ name: 'copyright', content: this.pageSeo.Meta_Copyright });
        this.meta.updateTag({ name: 'Distribution', content: this.pageSeo.Meta_Distribution });

        this.meta.updateTag({ itemprop: 'name', content: this.pageSeo.Meta_Item_Prop_Name });
        this.meta.updateTag({ itemprop: 'image', content: this.pageSeo.Meta_Item_Prop_Image });
        this.meta.updateTag({ itemprop: 'description', content: this.pageSeo.Meta_Item_Prop_Description });
        this.meta.updateTag({ property: 'article:author', content: this.pageSeo.Meta_Property_Article_Author });
        this.meta.updateTag({ property: 'article:publisher', content: this.pageSeo.Meta_Property_Article_Publisher });


        this.meta.updateTag({ property: 'og:type', content: this.pageSeo.Meta_Property_OG_Type });
        this.meta.updateTag({ property: 'og:url', content: this.pageSeo.Meta_Property_OG_URL });
        this.meta.updateTag({ property: 'og:site_name', content: this.pageSeo.Meta_Property_OG_SiteName });
        this.meta.updateTag({ property: 'og:description', content: this.pageSeo.Meta_Property_OG_Description });
        this.meta.updateTag({ property: 'og:title', content: this.pageSeo.Meta_Property_OG_Title });
        this.meta.updateTag({ property: 'og:author', content: this.pageSeo.Meta_Property_OG_Author });
        this.meta.updateTag({ property: 'og:image', content: this.pageSeo.Meta_Property_OG_Image });
      }
    });

    this.courseServer.clientgethomecourse().subscribe(Response => {

      this.popularCourse = Response.sort((a, b) => a.Position - b.Position);

      this.position1 = this.popularCourse[0].Course_URL;
      this.position2 = this.popularCourse[1].Course_URL;
      this.position3 = this.popularCourse[2].Course_URL;
      this.position4 = this.popularCourse[3].Course_URL;
      this.position5 = this.popularCourse[4].Course_URL;
      this.ptitle1 = this.popularCourse[0].Course_Title;
      this.ptitle2 = this.popularCourse[1].Course_Title;
      this.ptitle3 = this.popularCourse[2].Course_Title;
      this.ptitle4 = this.popularCourse[3].Course_Title;
      this.ptitle5 = this.popularCourse[4].Course_Title;
    });

    this.pageService.getBanner().subscribe(Response => {
      this.bannerArr = Response;

    });

    this.pageService.testimonials().subscribe(Response => {
      this.testimonials = Response;
    });

    this.pageService.Webinarlist({"Current_Date" : new Date()}).subscribe(Response => {
      if(Response && Response.length > 0){
        var webinars = Response.sort((a,b) => b.Webinar_Date_Time.localeCompare(a.Webinar_Date_Time));;
        this.webinar = webinars.splice(0,4)
        
      }
    });
    


  }
}
