import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';

// page import

import { AllCourseComponent } from './all-course/all-course.component';
import { CoursedetailsComponent } from './coursedetails/coursedetails.component';
import { CartComponent } from '../pages/cart/cart.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { WebinarsComponent } from '../webinars/webinars.component';
import { CourseComponent } from './course.component';

export const routes: Routes = [
  {
    path: 'course',
    component: CourseComponent,
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full' },
      { path: 'all', component: AllCourseComponent },
      { path: 'category/:Category', component: AllCourseComponent },
      { path: 'category/:Category/:courseCategory', component: AllCourseComponent },
      { path: ':Course_url', component: CoursedetailsComponent},
    ]
  }


];

export const CourseRouting: ModuleWithProviders = RouterModule.forChild(routes);

