import { Component, OnInit } from '@angular/core';
import { TrainerService } from 'src/app/service/trainer.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
export interface Trainer {
  Email: string;
  Name: string;
  Phone_Number: number;
  Profile_Photo: string;
  Skills: string;
}
export interface TrainerCourse {
  Course_Category_Id: string;
  Course_Description: string;
  Course_Name: string;
  Course_Video: string;
  Trainer_Id: string;
  Study_Material: string;
  _id: string;
}
@Component({
  selector: 'app-trainerdetail',
  templateUrl: './trainerdetail.component.html',
  styleUrls: ['./trainerdetail.component.css']
})
export class TrainerdetailComponent implements OnInit {
courseList: any[];
trainer: Trainer;
courseDetails: TrainerCourse;
courseDetailsSwitch: string;
courseId: string;
  constructor(private trainerService: TrainerService, private router: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit() {
    // this.courseDetailsSwitch = '5d84a4873f3e40746c77bf76';
    this.router.params.subscribe((params: Params) => {
      this.trainerService.getTrainerDetails(params.tid).subscribe(Response => {
        this.courseList = Response[`response`];
        this.courseDetails = this.courseList[`0`];
        this.courseId =  this.courseDetails[`_id`];
        this.trainer = Response[`Trainer_Info`];
        this.courseDetailsSwitch = this.courseId;
        this.trainerService.trainerCourseDetails(this.courseId).subscribe( res => {
          this.courseDetails = res[`0`];
        });
      });
    });

  }
  // openCourseDetails(courseId) {
  //   const dialogRef = this.dialog.open(TrainercourseDialogComponent, {
  //     data: {
  //       cid: courseId
  //     }
  //   });
  // }

  onTab(id) {
    this.courseDetailsSwitch = id;
    this.trainerService.trainerCourseDetails(id).subscribe( Response => {
      this.courseDetails = Response[`0`];
    });
  }
  }

