import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import{ OnlinecourseComponent } from './onlinecourse.component';
import { RatingModule } from 'ng-starrating';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    OnlinecourseComponent
  ],
  imports: [
    CommonModule,
    RatingModule,
    RouterModule
  ],
  exports: [
    RatingModule,
    OnlinecourseComponent
],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
 
})
export class OnlineCourseModule { }
