import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// page import
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { JobsComponent } from './jobs/jobs.component';
import { ProjectComponent } from './project/project.component';
import { CartComponent } from './cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { FaqComponent } from './faq/faq.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { TrainerComponent } from './trainer/trainer.component';
import { VerifyComponent } from './verify/verify.component';
import { ResultComponent } from './result/result.component';
import { TrainerdetailComponent } from './trainer/trainerdetail/trainerdetail.component';
import { JobdetailsComponent } from './jobs/jobdetails/jobdetails.component';
import { ProjectDetailsComponent } from './project/project-details/project-details.component';
import { ForumComponent } from './forum/forum.component';
import { ForumdetailsComponent } from './forum/forumdetails/forumdetails.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { WebinardetailComponent } from 'src/app/webinardetail/webinardetail.component';
import { PaymentTestComponent } from '../payment-test/payment-test.component';
import { WebinarsComponent } from '../webinars/webinars.component';
import { PagesComponent } from './pages.component';



export const routes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: './home/home.module#HomeModule' },
      { path: 'jobdetails/:jobid', component: JobdetailsComponent },
      { path: 'about', component: AboutComponent },
      { path: 'jobs', component: JobsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'project', component: ProjectComponent },
      { path: 'cart', component: CartComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'term', component: TermConditionComponent },
      { path: 'trainer', component: TrainerComponent },
      { path: 'trainerdetails/:tid', component: TrainerdetailComponent },
      { path: 'projectdetails/:category/:id', component: ProjectDetailsComponent },
      { path: 'verify', component: VerifyComponent },
      { path: 'result', component: ResultComponent },
      { path: 'forum', component: ForumComponent },
      { path: 'forumdetails/:id', component: ForumdetailsComponent },
      { path: 'user/:id', component: UserprofileComponent },
      { path: 'webinardetail/:id', component: WebinardetailComponent },
      { path: 'paymenttest', component: PaymentTestComponent },
      { path: 'webinars', component: WebinarsComponent }
    ]
  }
];

export const PageRouting: ModuleWithProviders = RouterModule.forChild(routes);
