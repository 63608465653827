import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { MatDialog } from '@angular/material';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ProjectCurriculam, Project, Miniproject } from 'src/app/pages/project/project.module';
import { Seo } from 'src/app/course/courseseo';
import { Title, Meta } from '@angular/platform-browser';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  projectSeo: Seo;

  constructor(private courseService: CourseService, private route: ActivatedRoute, private router: Router,
              private authService: AuthService, public dialog: MatDialog, private pageServices: PageService,
              private title: Title, private meta: Meta ) { }
  projectDetails: Project;
  ProjectCurriculam: ProjectCurriculam[];
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.pageServices.getProjectDetails(params.id).subscribe( Response => {
        this.projectDetails = Response[`0`];
        this.ProjectCurriculam = this.projectDetails.Project_Curriculam;
        this.projectSeo = this.projectDetails.SEO[`0`];
        this.title.setTitle(this.projectDetails.Project_Title);
        if (typeof(this.projectSeo) !== 'undefined') {
          this.meta.updateTag({name: 'description', content: this.projectSeo.Meta_Description});
          this.meta.updateTag({name: 'keywords', content: this.projectSeo.Meta_Keywords});
          this.meta.updateTag({name: 'abstract', content: this.projectSeo.Meta_Abstract });
          this.meta.updateTag({name: 'Author', content: this.projectSeo.Meta_Author });
          this.meta.updateTag({name: 'copyright', content: this.projectSeo.Meta_Copyright });
          this.meta.updateTag({name: 'Distribution', content: this.projectSeo.Meta_Distribution });

          this.meta.updateTag({itemprop: 'name', content: this.projectSeo.Meta_Item_Prop_Name  });
          this.meta.updateTag({itemprop: 'image', content: this.projectSeo.Meta_Item_Prop_Image  });
          this.meta.updateTag({itemprop: 'description', content: this.projectSeo.Meta_Item_Prop_Description });
          this.meta.updateTag({property: 'article:author', content: this.projectSeo.Meta_Property_Article_Author });
          this.meta.updateTag({property: 'article:publisher', content: this.projectSeo.Meta_Property_Article_Publisher });


          this.meta.updateTag({property: 'og:type', content: this.projectSeo.Meta_Property_OG_Type });
          this.meta.updateTag({property: 'og:url', content: this.projectSeo.Meta_Property_OG_URL });
          this.meta.updateTag({property: 'og:site_name', content: this.projectSeo.Meta_Property_OG_SiteName });
          this.meta.updateTag({property: 'og:description', content: this.projectSeo.Meta_Property_OG_Description });
          this.meta.updateTag({property: 'og:title', content: this.projectSeo.Meta_Property_OG_Title });
          this.meta.updateTag({property: 'og:author', content: this.projectSeo.Meta_Property_OG_Author });
          this.meta.updateTag({property: 'og:image', content: this.projectSeo.Meta_Property_OG_Image });
        }
      });
    });
  }
  projectEnroll(courseId) {
    const projectDate = {
      Project_Id: courseId,
      Purchase_Type : 'Project',

    };
    if (this.authService.isLogin() === true) {
      this.pageServices.addCart(projectDate).subscribe( Response => {
        this.router.navigate(['pages/cart']);
      });
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '620px',
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

  }

}
