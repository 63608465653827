import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AllCourseComponent } from '../course/all-course/all-course.component';
import { CoursedetailsComponent } from './coursedetails/coursedetails.component';
import { TimelineComponent } from './timeline/timeline.component';
import { BatchesdialogComponent } from './batchesdialog/batchesdialog.component';
import { AngularMaterialModule } from '../shared/angular-material/angular-material.module';
import { ReviewComponent } from './review/review.component';
import { PagesModule } from '../pages/pages.module';
import { LoginComponent } from '../auth/login/login.component';
import { AuthModule } from '../auth/auth.module';
import { RatingModule } from 'ng-starrating';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { DialogBoxService } from '../dialog-box/dialog-box.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OnlineCourseModule } from './onlinecourse/online-course.module';
import { FooterModule } from '../layout/footer/footer.module';
import { CourseRouting } from './course.routing';
import { CourseComponent } from './course.component';

@NgModule({
  declarations: [
    CourseComponent,
    AllCourseComponent,
    CoursedetailsComponent,
    TimelineComponent,
    BatchesdialogComponent,
    ReviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    PagesModule,
    CourseRouting,
    AngularMaterialModule,
    RatingModule,
    MatDialogModule,
    NgbModule,
    NgxSpinnerModule,
    OnlineCourseModule,
    FooterModule

  ],
  exports: [
    AngularMaterialModule,
    ReviewComponent
  ],
  entryComponents: [BatchesdialogComponent, LoginComponent, DialogBoxComponent],
  providers: [DialogBoxService],
})
export class CourseModule { }
