import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';



export interface DialogData {
  userName: string;
  password: string;
}

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  activeDiv: string;
  message: string;
  isLoading = false;
  verifyForm = new FormGroup({
    Email: new FormControl('', [Validators.email, Validators.required])
  });
  otpForm = new FormGroup({
    otp: new FormControl('', Validators.required)
  });
  resetPasswordForm = new FormGroup({
    Password: new FormControl('', Validators.required)
  });

  forgotPassword: boolean;
  email: string;
  constructor(private authService: AuthService ) { }
      onForgot() {
        this.forgotPassword = true;
      }

      verifyEmail() {
        const email = {
          Email: this.verifyForm.value.Email
        };
        this.email = this.verifyForm.value.Email;
        this.isLoading = true;
        this.authService.verifyEmail(email).subscribe( Response => {
          this.isLoading = false;
          if (Response[`success`] === true) {

            this.activeDiv = 'otpVerify';
            this.message = 'Enter OTP';
          } else {
            this.message = 'Email not Exist';
          }

        });
      }
      submitOtp() {
        const otpData = {
          Email: this.email,
          OTP: this.otpForm.value.otp
        };
        this.isLoading = true;
        this.authService.verifyOtp(otpData).subscribe( Response => {
          this.isLoading = false;
          if (Response[`success`] === true) {
            this.email = Response[`response`].Email;
            this.activeDiv = 'changePassword';
            this.message = '';
          } else {
            this.message = 'OTP is not Verified';
          }


        });
      }
      resetPassword() {
        const resetData = {
          Email: this.email,
          Password: this.resetPasswordForm.value.Password
        };
        this.isLoading = true;
        this.authService.resetPassword(resetData).subscribe( Response => {
          this.isLoading = false;
          if (Response[`success`] === true) {
            this.activeDiv = 'success';
            this.message = 'Password is Change Successfully';
          } else {
            this.message = 'Sorry! Could not reset the password';
          }

        });
      }

  ngOnInit() {
    this.activeDiv = 'emailVerify';
  }

}
