import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { DashboardInfo } from '../studentdashboard/dashboadrinfo';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  coursecategory: any[];


  // tslint:disable-next-line: no-shadowed-variable
  constructor(private http: HttpClient , private AuthService: AuthService) { }


  PostProfileData(formData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'register/updateprofile ', formData).pipe(map(res => {
      return res;
    }));
  }

  getProfile() {
    return this.http.post( environment.MICRO_SERVICE_API + 'register/getprofile', {}).pipe(map(res => {
      return res[`response`];
    }));
  }

  enrollCourse() {
    return this.http.post( environment.MICRO_SERVICE_API + 'mycourses/getmycourses', {})
      .pipe(map(res => {
        if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  courseForum(userId) {
    return this.http.post( environment.MICRO_SERVICE_API + 'courseforum/addcourseforum', userId)
      .pipe(map(res => {
        if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  dashboard(): Observable<DashboardInfo> {
    return this.http.post( environment.MICRO_SERVICE_API + 'analysis/getuserdashboard', {})
      .pipe(map(res => {
        if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  myOrders() {
    return this.http.post( environment.MICRO_SERVICE_API + 'payment/myorders', {})
      .pipe(map(res => {
        if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  getActivityLog() {
    return this.http.post( environment.MICRO_SERVICE_API + 'analysis/getuseractivities', {})
      .pipe(map(res => {
        if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  getJobList() {
    return this.http.post( environment.MICRO_SERVICE_API + 'joblisting/findappliedjobs', {})
      .pipe(map(res => {
       if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  getMiniProject() {
    return this.http.post( environment.MICRO_SERVICE_API + 'payment/myprojects', {})
      .pipe(map(res => {
       if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  getProjectDetails(ProjectId) {
    return this.http.post( environment.MICRO_SERVICE_API + 'payment/projectdetails', {Project_Id: ProjectId})
      .pipe(map(res => {
       if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  changePassword(password) {
    return this.http.post( environment.MICRO_SERVICE_API + 'forgotpassword/changepassword', password)
      .pipe(map(res => {
        return res;
    }));
  }
  addNote(Note) {
    return this.http.post( environment.MICRO_SERVICE_API + 'notes/create', Note)
      .pipe(map(res => {
        return res;
    }));
  }
  findNote() {
    return this.http.post( environment.MICRO_SERVICE_API + 'notes/find', {})
      .pipe(map(res => {
       if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }
  deleteNote(noteId) {
    return this.http.post( environment.MICRO_SERVICE_API + 'notes/delete', {Note_Id: noteId})
      .pipe(map(res => {
       if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
    }));
  }

  uploadResume(resume) {
    return this.http.post( environment.MICRO_SERVICE_API + 'register/uploadresume', resume)
    .pipe(map(res => {
     if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res;
        }
  }));
  }

  reffrelCode(Email) {
    return this.http.post( environment.MICRO_SERVICE_API + 'register/referafriend', Email)
    .pipe(map(res => {
     if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res;
        }
  }));
  }

  getForam() {
    return this.http.post( environment.MICRO_SERVICE_API + 'courseforum/usercourseforum', {})
    .pipe(map(res => {
     return res[`response`];
  }));
  }

  replyForam(replyData) {
    return this.http.post( environment.MICRO_SERVICE_API + 'courseforum/updatecourseforum', replyData)
    .pipe(map(res => {
     if (res[`clearCookie`]) {
          this.AuthService.logOut();
        } else {
          return res[`response`];
        }
  }));
  }

}





