import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CourseService } from 'src/app/service/course.service';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  isLoading = false;
  orderData: any[];
  ticketList: any[];
  supportForm = new FormGroup({
    Course_Id: new FormControl('', Validators.required),
    Support_Type: new FormControl('', Validators.required),
    Question: new FormControl('', Validators.required),
    Description: new FormControl('', Validators.required)
  });
  replyForm = new FormGroup({
    Message: new FormControl('', Validators.required)
  });

  constructor( private courseService: CourseService, private dashboardService: DashboardService) { }

  ngOnInit() {
    this.courseService.getTicketList().subscribe( Response => {
      this.ticketList = Response;
    });
    this.dashboardService.myOrders().subscribe( Response => {
      this.orderData = Response;
    });
  }
  replyTicket(tid) {
    const data = {
      User_Type: 'User',
      Message: this.replyForm.value.Message,
      Ticket_Id: tid,
    };
    this.isLoading = true;
    this.courseService.replyTicket(data).subscribe( Response => {
      this.ticketList.find( res => res.Ticket_Id === Response.Ticket_Id ).Conversation = Response.Conversation;
      this.isLoading = false;
      this.replyForm.reset();
    });
  }
  submitSupport() {
    const formData = {
      Course_Id: this.supportForm.value.Course_Id,
      Support_Type: this.supportForm.value.Support_Type,
      Question: this.supportForm.value.Question,
      Description: this.supportForm.value.Description,
    };
    this.courseService.submitTicket(formData).subscribe(Response => {
      this.supportForm.reset();
    });
  }
}
