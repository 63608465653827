import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/service/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { TrainerService } from 'src/app/service/trainer.service';

@Component({
  selector: 'app-trainerform',
  templateUrl: './trainerform.component.html',
  styleUrls: ['./trainerform.component.css']
})
export class TrainerformComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });
  signUpform = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    mobile: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });
  // tslint:disable-next-line: no-input-rename
  isLoginMode = true;
  isloding = false;
  signupSuccess = false;
  forgotPassword: boolean;
  constructor( public dialogRef: MatDialog, private trainerService: TrainerService, private authService: AuthService,
               private router: Router, private cookieService: CookieService ) {
                }
      onSwitchLogin() {
          this.isLoginMode = !this.isLoginMode;
      }
      onSignUp() {
        // if (!signUpData.valid) {
        //   return;
        // }
        const trainerData = {
          Name: this.signUpform.value.userName,
          Email: this.signUpform.value.email,
          Phone_Number: this.signUpform.value.mobile,
          Password: this.signUpform.value.password,
        };
        this.trainerService.trainerSignUp(trainerData).subscribe(resData => {
          this.signupSuccess = true;
        });
        this.signUpform.reset();
      }
      ngOnInit() {
      }
}
