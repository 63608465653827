import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-miniproject-details',
  templateUrl: './miniproject-details.component.html',
  styleUrls: ['./miniproject-details.component.css']
})
export class MiniprojectDetailsComponent implements OnInit {
  project: any;
  Curriculam: any[];
  constructor(private dashboardService: DashboardService, private route: ActivatedRoute) { }
  ngOnInit() {
    this.route.params.subscribe((prams: Params) => {
      this.dashboardService.getProjectDetails(prams.pid).subscribe(Response => {
        this.project = Response;
        this.Curriculam = Response[`Project_Curriculam`];
        });
  });

}
}
