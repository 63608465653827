import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-payment-test',
  templateUrl: './payment-test.component.html',
  styleUrls: ['./payment-test.component.css']
})
export class PaymentTestComponent implements OnInit {
  
  constructor(){
    
  }

  ngOnInit(): void {

  }

  alertResponse(res){
    console.log(res);
  }

  handleResponse(res) {
    alert('Response received');
    console.log('Response in pipe separated string to verify the transaction status' + res.stringResponse);
    console.log('Complete response:');
    console.log(res);
    //this.alertResponse(res)
  }

  proccedNow() {
    let returnUrl = 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp';
    let configJson = {
      'tarCall': false,
      'features': {
        'showPGResponseMsg': false,
        'enableExpressPay': true,
        'enableNewWindowFlow': true    //for hybrid applications please disable this by passing false
      },
      'consumerData': {
        'deviceId': 'WEBSH2',	//possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
        'token': 'D8364F11D14C8B7920733F3E2079FA63F6FA31CE6F7EC37C3493E34D10AD3EB8CA652CC683DB04F98881F41D7F0E96CED0ABE1DC1612B6B211A1480DCAA554F6',
        'returnUrl': returnUrl,
        'responseHandler': this.handleResponse,
        'paymentMode': 'all',
        'merchantId': 'T301890',
        'currency': 'INR',
        'consumerId': 'c964634',
        'consumerMobileNo': '9876543210',
        'consumerEmailId': 'test@test.com',
        'txnId': '2481197581116',   //Unique merchant transaction ID
        'items': [{
          'itemId': 'first',
          'amount': '2',
          'comAmt': '0'
        }],
        'customStyle': {
          'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
          'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
          'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
          'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
        }
      }
    };
    /*if (returnUrl) {
      configJson.consumerData["returnUrl"] = returnUrl;
    }*/
    var checkoutLoop = setInterval(function () {
      if (typeof $["pnCheckout"] != 'undefined') {
        $["pnCheckout"](configJson);
        if (configJson.features.enableNewWindowFlow) {
          window["pnCheckoutShared"].openNewWindow();
        }
        clearInterval(checkoutLoop);
      }
    }, 500);
  }
}