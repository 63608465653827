import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Course, Price } from '../course';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BatchesdialogComponent } from '../batchesdialog/batchesdialog.component';
import { CourseService } from 'src/app/service/course.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { Seo } from '../courseseo';
import { PageService } from 'src/app/service/page.service';
import { StarRatingComponent } from 'ng-starrating';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';
import { DialogBoxService } from 'src/app/dialog-box/dialog-box.service';
import * as _ from 'lodash';
// import { RatingreviewComponent } from 'src/app/ratingreview/ratingreview.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

interface Offer {
  Coupon_Code: string;
  Discount_Percentage: number;
  End_Date: Date;
  Maximum_Amount: number;
  Offer_Image: string;
  Offer_Name: string;
  Offer_Type: string;
  Start_Date: Date;
}

@Component({
  selector: 'app-coursedetails',
  templateUrl: './coursedetails.component.html',
  styleUrls: ['./coursedetails.component.css']
})
export class CoursedetailsComponent implements OnInit {
  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    responsiveClass: true,
    navSpeed: 700,
    navText: ['<i class="fa fas-arrow arr-l fa-chevron-left"></i>', '<i class="fa fas-arrow arr-r fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  panelOpenState = false;
  reviewForm = new FormGroup({
    rating: new FormControl(),
    review: new FormControl('', Validators.required)
  });
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', Validators.required),
    employee: new FormControl('', Validators.required),

  });
  applyCorporateForm: boolean;
  course: Course;
  CourseId: string;
  CourseCurriculam: any[];
  courseDetails: any[];
  courseFaq: any[];
  courseFeatures: any[];
  Schedules: any[];
  courseURL: string;
  pricing: Price[];
  classRoom: Price;
  online: Price;
  selfpaced: Price;
  courseSeo: Seo;
  recentAddedCourse: any[];
  Average_Rating: string = "";
  Total_Reviewers: number = 0;
  Five_Star: number = 0;
  Four_Star: number = 0;
  Three_Star: number = 0;
  Two_Star: number = 0;
  One_Star: number = 0;
  course1: any[];
  review: any[];
  Total_Reviewers1: number;
  allCourseList: any;
  constructor(private modalService: NgbModal, private courseService: CourseService,
    private rout: ActivatedRoute, private title: Title, private meta: Meta,
    public dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,
    private authService: AuthService,
    private pageService: PageService,
    private dialogBoxService: DialogBoxService, ) { }
  offer: Offer;
  coupan: 'NA';
  getOffer: boolean;
  error: {};
  closeResult: string;
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    // if(this.Average_Rating=='0'){
    //   console.log("test")
    //   document.getElementById('review-model-popup').style.pointerEvents = "none";
    // }

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.applyCorporateForm = false;
    this.courseService.recentAddedCourse().subscribe(Response => {
      this.recentAddedCourse = Response;
    });
    this.rout.params.subscribe(
      (prams: Params) => {
        this.CourseId = prams[`Course_url`];
      
        this.courseService.getCourseDetails(this.CourseId).subscribe(Response => {
          this.course = Response;
         
          this.CourseCurriculam = Response.Course_Curriculam;
          this.courseDetails = Response.Course_Details;

          this.courseFaq = Response.FAQs;
          this.courseURL = Response.Course_URL;
          if (this.courseURL == undefined) {
            this.router.navigateByUrl('/error/')
          }
          this.courseFeatures = Response.Features;
          this.Schedules = Response.Schedules;
          this.pricing = Response.Pricing;
          this.courseSeo = Response.CourseSEO[`0`];
          let Reviews = Response.CourseReview
          this.Total_Reviewers = Reviews.length
          let Total_Review = 0;
          Reviews.forEach(element => { Total_Review += element['Review_Number']; });
          this.Average_Rating = Total_Review > 0 && this.Total_Reviewers > 0 ? (Total_Review / this.Total_Reviewers).toFixed(1) : "0";
          if (this.Average_Rating == '0') {

            document.getElementById('review-model-popup').style.pointerEvents = "none";
          }

          this.Five_Star = (Reviews.filter(x => x['Review_Number'] == 5).length / this.Total_Reviewers) * 100
          this.Four_Star = (Reviews.filter(x => x['Review_Number'] == 4).length / this.Total_Reviewers) * 100
          this.Three_Star = (Reviews.filter(x => x['Review_Number'] == 3).length / this.Total_Reviewers) * 100
          this.Two_Star = (Reviews.filter(x => x['Review_Number'] == 2).length / this.Total_Reviewers) * 100
          this.One_Star = (Reviews.filter(x => x['Review_Number'] == 1).length / this.Total_Reviewers) * 100

          this.classRoom = this.pricing.find(res => res.Course_Type === 'Classroom');
          this.online = this.pricing.find(res => res.Course_Type === 'Webinar');
          this.selfpaced = this.pricing.find(res => res.Course_Type === 'Online');
          if (typeof (this.courseSeo) !== 'undefined') {
            this.title.setTitle(this.courseSeo.Meta_Title);
            this.meta.updateTag({ name: 'description', content: this.courseSeo.Meta_Description });
            this.meta.updateTag({ name: 'keywords', content: this.courseSeo.Meta_Keywords });
            this.meta.updateTag({ name: 'abstract', content: this.courseSeo.Meta_Abstract });
            this.meta.updateTag({ name: 'Author', content: this.courseSeo.Meta_Author });
            this.meta.updateTag({ name: 'copyright', content: this.courseSeo.Meta_Copyright });
            this.meta.updateTag({ name: 'Distribution', content: this.courseSeo.Meta_Distribution });

            this.meta.updateTag({ itemprop: 'name', content: this.courseSeo.Meta_Item_Prop_Name });
            this.meta.updateTag({ itemprop: 'image', content: this.courseSeo.Meta_Item_Prop_Image });
            this.meta.updateTag({ itemprop: 'description', content: this.courseSeo.Meta_Item_Prop_Description });
            this.meta.updateTag({ property: 'article:author', content: this.courseSeo.Meta_Property_Article_Author });
            this.meta.updateTag({ property: 'article:publisher', content: this.courseSeo.Meta_Property_Article_Publisher });


            this.meta.updateTag({ property: 'og:type', content: this.courseSeo.Meta_Property_OG_Type });
            this.meta.updateTag({ property: 'og:url', content: this.courseSeo.Meta_Property_OG_URL });
            this.meta.updateTag({ property: 'og:site_name', content: this.courseSeo.Meta_Property_OG_SiteName });
            this.meta.updateTag({ property: 'og:description', content: this.courseSeo.Meta_Property_OG_Description });
            this.meta.updateTag({ property: 'og:title', content: this.courseSeo.Meta_Property_OG_Title });
            this.meta.updateTag({ property: 'og:author', content: this.courseSeo.Meta_Property_OG_Author });
            this.meta.updateTag({ property: 'og:image', content: this.courseSeo.Meta_Property_OG_Image });
          }

          this.courseService.review(Response._id).subscribe(response => {
            this.review = _.map(response, function (obj) {
              if (_.isEmpty(_.get(obj, 'User_Info.Name'))) {
                obj.User_Info = {}
                obj.User_Info['Name'] = 'Anonymous User';
              } else {
                obj.User_Info['Name'] = obj.User_Info['Name'];
              }
              return obj;
            })

          });

        }
        );
      });

    this.pageService.offers().subscribe((Response: Offer) => {
      this.offer = Response;
      this.getOffer = true;
    });

  }
  enrollHeader() {
    window.scrollTo({
      top: 650,
      left: 0,
      behavior: 'smooth'
    });

  }
  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    alert(`Old Value:${$event.oldValue}, 
      New Value: ${$event.newValue}, 
      Checked Color: ${$event.starRating.checkedcolor}, 
      Unchecked Color: ${$event.starRating.uncheckedcolor},
      readonly: ${$event.starRating.readonly}`);
  }

  myCtrl($scope) {
    $scope.isReadonly = false; // default test value
    $scope.changeOnHover = false; // default test value 

  }
  openBatches(courseType): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      Schedules: this.Schedules.filter(resp => resp.Schedule_Type === courseType),
      courseName: this.course.Course_Title,
      courseURL: this.course.Course_URL,
      courseTypes: courseType,
      courseId: this.course._id
    };
    const dialogRef = this.dialog.open(BatchesdialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  corporateForm() {
    const formDate = {
      Name: this.form.value.name,
      Phone_Number: this.form.value.mobile,
      Email: this.form.value.email,
      Company: this.form.value.company,
      User_Type: 'Corporate',
      No_Of_Employees: this.form.value.employee
    };
    this.pageService.postFormData(formDate).subscribe(Response => {
      this.form.reset();
      this.applyCorporateForm = true;
    });
  }
  onEnroll(courseId) {
    const enrollDate = {
      Course_Id: courseId,
      Course_Type: 'Online',
      Purchase_Type: 'Course',
    };
    if (this.authService.isLogin() === true) {
      this.pageService.addCart(enrollDate).subscribe(Response => {
        this.router.navigate(['pages/cart']);
      });
    } else {
      this.dialog.open(LoginComponent, {
        width: '620px'
      });
    }

  }

  submitReview() {
    const subbmitData = {
      Course_Id: this.course._id,
      // tslint:disable-next-line: max-line-length
      Review_Message: this.reviewForm.value.review,
      Review_Number: this.reviewForm.value.rating
    };
    if (this.authService.isLogin() === true) {
      this.courseService.reviewData(subbmitData).subscribe(Response => {
        this.snackBar.open('Thanks For Submit Review', subbmitData.Review_Number, {
          duration: 2000,
        });
      });
      this.reviewForm.reset();
    } else {
      this.dialog.open(LoginComponent, {
        width: '620px'
      });
    }

  }

  openDialog() {
    this.dialog.open(DialogBoxComponent
      , {
        height: '400px',
        width: '600px',
        maxWidth: '100%',
        autoFocus: false,
        panelClass: 'dialog-box',
      }
    );
  }
  public showCourseDescription(heading: string) {

    this.dialogBoxService.confirm(this.courseFeatures.find(x => x['Feature_Title'] == heading))
      .then((confirmed) => console.log('User confirmed:', confirmed))
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}