import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map} from 'rxjs/operators';
import { throwError , BehaviorSubject} from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../auth/user.module';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})

export class AuthService {
 user = new BehaviorSubject<User>(null);
 cookieValue = '';

  constructor(private http: HttpClient, private router: Router , private cookieService: CookieService) {}

  signUp(signUpData) {
    return  this.http.post(environment.MICRO_SERVICE_API + 'register', signUpData ).pipe(catchError(this.handleError));
  }


  login(userId: string, password: string) {
        return this.http.post<any>(environment.MICRO_SERVICE_API + 'login', {email: userId, password})
        .pipe(catchError(this.handleError), tap(resData => {
                    const user = new User(resData.Token);
                    this.user.next(user);
    })
    );
  }
  logOut() {
    return this.http.get(environment.MICRO_SERVICE_API + 'login/logout').pipe(map(Response => {
      localStorage.clear();
      this.user.next(null);
      this.router.navigate(['/']).then(() => {
        location.reload();
      });
      return Response;
    }));

  }
  verifyEmail(email) {
    return this.http.post(environment.MICRO_SERVICE_API + 'forgotpassword/verifyemail', email).pipe(map(res => {
      return res;
    }));
  }
  verifyOtp(otp) {
    return this.http.post(environment.MICRO_SERVICE_API + 'forgotpassword/verifyotp', otp).pipe(map(res => {
      return res;
    }));
  }
  resetPassword(reset) {
    return this.http.post(environment.MICRO_SERVICE_API + 'forgotpassword/resetpassword', reset).pipe(map(res => {
      return res;
    }));
  }

  getAuthToken() {
    return this.cookieValue;
  }

  isLogin() {
    if (this.cookieValue) {
      return true;
    }
    return false;
  }
autologin() {
    this.cookieValue = localStorage.getItem('auth');
    if (!this.cookieValue) {
      this.cookieValue = '';
      this.user.next(null);
      return;
    }
    const loadedUser = new User(this.cookieValue);
    if (loadedUser.authtoken) {
      this.user.next(loadedUser);
    }
    // this.router.navigate(['/']);
  }
  googleLogin(data) {
    return this.http.post(environment.MICRO_SERVICE_API + 'social/loginwithgmail', data)
                    .pipe(catchError(this.handleError), tap(resData => {
                        const user = new User(resData[`Token`]);
                        this.user.next(user);
                    }));
  }

  facebookLogin(data) {
    return this.http.post(environment.MICRO_SERVICE_API + 'social/loginwithfacebook', data)
                  .pipe(catchError(this.handleError), tap(resData => {
                      const user = new User(resData[`Token`]);
                      this.user.next(user);
                  }));
  }

 private handleError(errorRes: HttpErrorResponse) {
     if (!errorRes.error) {
      return throwError('user authentication is wrong');
    }
     return throwError('nothing');
 }
}



