import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css']
})

export class ActivityLogComponent implements OnInit {
  activityLog: any[];
  constructor( private dashbordService: DashboardService) { }

  ngOnInit() {
    this.dashbordService.getActivityLog().subscribe( Response => {
      this.activityLog = Response;
    });
  }

}
