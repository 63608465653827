import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { OnlineCourseModule } from 'src/app/course/onlinecourse/online-course.module';
import { AngularMaterialModule } from '../../shared/angular-material/angular-material.module';
import { JobModule } from '../jobs/jobs.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterModule } from 'src/app/layout/footer/footer.module';
import { WebinarformModule } from 'src/app/webinarform/webinarform.module';

export const routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' }
  
];


@NgModule({
  declarations: [
   HomeComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes),
    RouterModule,
    OnlineCourseModule,
    AngularMaterialModule,
    JobModule,
    NgxSpinnerModule,
    FooterModule,
    WebinarformModule 
  ],
  exports:[HomeComponent]
  
})
export class HomeModule { }
