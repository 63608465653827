import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/service/page.service';
import {  ActivatedRoute, Params } from '@angular/router';

export interface UserProfile {
Email: string;
Name: string;
Phone_Number: number;
Referal_Code?: string;
Resume_URL?: string;
Profile_Image: string;
_id: string;
}
@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})

export class UserprofileComponent implements OnInit {
  user: UserProfile;
  constructor( private pageServer: PageService, private router: ActivatedRoute) { }

  ngOnInit() {
    this.router.params.subscribe((params: Params) => {
      this.pageServer.userProfile(params.id).subscribe(Response => {
        this.user = Response;
        
      });
    });
  }

}
